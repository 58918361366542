import React from 'react';
import CourseItem from './courseitem';

function CourseTable(){
  return(
    <>
    <div class="container mt-8">
      <div class="tab-content"id="course-pills-tabContent">
    <div class="tab-pane fade show active" id="course-pills-tabs-1" role="tabpanel" aria-labelledby="course-pills-tab-1">
				<div class="row g-4">

					{/* <!-- Card item START --> */}
				<CourseItem 
				  imageSrc="/img/power-bi.jpg"
					badge="Básico"
					title="Microsoft Power BI"
					preco_de="De: R$ 800,00"
					preco_por="Por: R$ 500,00"
					rating={4} // ou qualquer valor entre 0 e 5
					duration="20h"
					lectures={15}
					link='/business-intelligence'
				/>
					{/* <!-- Card item END --> */}

					{/* <!-- Card item START --> */}
					<CourseItem 
				  imageSrc="/img/banco-de-dados.jpg"
					badge="Intermediário"
					title="Banco de Dados"
					preco_de="De: R$ 800,00"
					preco_por="Por: R$ 500,00"
					description="Aprenda a construir dashboards funcionais para análise de dados"
					rating={4} // ou qualquer valor entre 0 e 5
					duration="20h"
					lectures={15}
					link='/banco-de-dados'
				/>
					{/* <!-- Card item END --> */}

					{/* <!-- Card item START --> */}
					<CourseItem 
				  imageSrc="/img/excel.jpg"
					badge="Nível"
					title="Em Elaboração"
					preco_de="De: R$ 0,00"
					preco_por="Por: R$ 0,00"
					description="Aprenda a construir dashboards funcionais para análise de dados"
					rating={4} // ou qualquer valor entre 0 e 5
					duration="0h"
					lectures={0}
				/>
					{/* <!-- Card item END --> */}

					{/* <!-- Card item START --> */}
					<CourseItem 
				  imageSrc="/img/python.jpg"
					badge="Nível"
					title="Em Elaboração"
					preco_de="De: R$ 0,00"
					preco_por="Por: R$ 0,00"
					description="Aprenda a construir dashboards funcionais para análise de dados"
					rating={4} // ou qualquer valor entre 0 e 5
					duration="0h"
					lectures={0}
				/>
					{/* <!-- A PARTIR DAQUI POSSO ADICIONAR MAIS CURSOS... --> */}
				</div>
			</div>
      </div>
      </div>
    </>
  )
}

export default CourseTable;

