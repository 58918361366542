import React from 'react'
import MenuProfile from './menuProfile'

function Usuarios(){

  return(
    <>
        <div class="bg-shape bg-dark">
        <div class="container">
            <div class="row">
                <div class="col-xl-12 col-lg-12 col-md-12 col-12">
                    <div class="pt-lg-8 pb-lg-12 pt-5 pb-0 mb-16 ">
                        <h1 class="h2 text-white mb-0">Usuários</h1>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="pb-12 mt-lg-n18 mt-n10">
        <div class="container">
            <div class="row">
                <MenuProfile/>
                {/* <!-- dashboard subscriptions --> */}
                <div class="col-lg-9 col-md-8 col-12">
                    <div class="card rounded-3 mb-4 ">
                        {/* <!-- card header --> */}
                        <div class="card-header bg-white p-4 border-bottom-0 d-flex justify-content-between align-items-center">
                            <h3 class="mb-0 h4">Usuários</h3>
                            <a href="#" class="btn btn-primary btn-sm" data-bs-toggle="modal" data-bs-target="#addNew">Adicionar Usuário</a>
                        </div>
                        {/* <!-- table --> */}
                        <div class="table-responsive border-0">
                            <table class="table mb-0 text-nowrap ">
                                <thead class="bg-light ">
                                    <tr>
                                        <th scope="col" class="py-2 border-bottom-0">Nome do Usuário</th>
                                        <th scope="col" class="py-2 border-bottom-0">
                                            Área de Atuação
                                        </th>

                                        <th scope="col" class="py-2 border-bottom-0">Nível do Perfil de Usuário</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td class="align-middle d-flex align-items-center p-3">
                                            <img src="../../assets/images/Membro-Eduardo.jpg" alt="" class="rounded-circle avatar-lg" />
                                            <div class="ms-3 lh-1">
                                                <h5 class="mb-0">Eduardo de Souza</h5>
                                                <span class="font-12">Eduardo@teste.com.br</span>
                                            </div>
                                        </td>
                                        <td class="align-middle p-3">Advogado</td>
                                        <td class="align-middle p-3">Concurseiro</td>
                                    </tr>
                                    <tr>
                                        <td class="align-middle d-flex align-items-center p-3">
                                            <img src="../../assets/images/team-raissa.jpg" alt="" class="rounded-circle avatar-lg" />
                                            <div class="ms-3 lh-1">
                                                <h5 class="mb-0">Raíssa França</h5>
                                                <span class="font-12">raissa@teste.com.br</span>
                                            </div>
                                        </td>
                                        <td class="align-middle p-3">Mestra em Letras</td>
                                        <td class="align-middle p-3">Professora</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    </>
  )
}
export default Usuarios;