import React from 'react'
import MenuProfile from './menuProfile'

function Seguranca(){
  return(
    <>
    <div class="bg-shape bg-dark">
        <div class="container">
            <div class="row">
                <div class="col-xl-12 col-lg-12 col-md-12 col-12">
                    <div class="pt-lg-8 pb-lg-12 pt-5 pb-0 mb-16 ">
                        <h1 class="h2 text-white mb-0">Segurança</h1>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="pb-12 mt-lg-n18 mt-n10">
        <div class="container">
            <div class="row">
                <MenuProfile/>
                {/* <!-- dashboard subscriptions --> */}
                <div class="col-lg-9 col-md-8 col-12">
                    <div class="card rounded-3 mb-4 ">
                        {/* <!-- card header --> */}
                        <div class="card-header bg-white p-4 d-flex justify-content-between align-items-center">
                            <h3 class="mb-0 h4">Senha</h3>
                            <a href="#" class="text-inherit font-14">Esqueceu sua senha?</a>
                        </div>
                        {/* <!-- card body --> */}
                        <div class="card-body p-4">
                            {/* <!-- form --> */}
                            <form class="row">
                                <div class="mb-3 col-12 col-md-8">
                                    <label class="form-label" for="currentPassword">Senha Atual</label>
                                    <input type="password" id="currentPassword" class="form-control" placeholder="************" required />
                                </div>
                                <div class="col-12 col-md-4"></div>
                                <div class="mb-3 col-12 col-md-8">
                                    <label class="form-label" for="newPassword">Nova Senha</label>
                                    <input type="password" id="newPassword" class="form-control" placeholder="************" required />
                                </div>
                                <div class="col-12 col-md-4"></div>
                                <div class="mb-3 col-12 col-md-8">
                                    <label class="form-label" for="confirmPassword">Confirme A Nova Senha</label>
                                    <input type="password" id="confirmPassword" class="form-control" placeholder="************" required />
                                </div>
                                <div class="col-12 col-md-4"></div>
                                <div class="col-12">
                                    <button class="btn btn-primary" type="submit">
                                        Atualizar sua Nova Senha
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                    {/* <!-- card --> */}
                    <div class="card rounded-3 mb-4 ">
                        {/* <!-- card header --> */}
                        <div class="card-header bg-white p-4">
                            <h3 class="mb-0 h4">Autenticação Dois Fatores</h3>
                        </div>
                        {/* <!-- card body --> */}
                        <div class="card-body p-6 text-center">
                            <i class="fe fe-lock font-24 text-primary mb-3 d-block"></i>
                            <h3>Autenticação em dois fatores ainda não está ativada</h3>
                            <p class="mb-4">
                                Realizar a autenticação de dois fatores adicionará uma camada de segurança a mais em sua conta.
                                Dessa forma, aconselhamos ativar essa autenticação para que tenha mais segurança ao realizar seu login.
                            </p>
                            <a href="#" class="btn btn-primary btn-sm">Ativar Autenticação de Dois Fatores</a>
                        </div>
                    </div>
                    {/* <!-- card --> */}
                    <div class="card rounded-3 mb-4 ">
                        {/* <!-- card header --> */}
                        <div class="card-header bg-white p-4">
                            <h3 class="mb-0 h4">Dados de sua última sessão</h3>
                        </div>
                        {/* <!-- card body --> */}
                        <div class="card-body p-4">
                            <p class="mb-0 font-14">
                                Esta é uma lista de dispositivos que fizeram login em sua conta.
                                Para sua segurança remova quaisquer sessões que você não reconheça.
                            </p>
                            {/* <!-- collapse one --> */}
                            <ul class="list-group list-group-flush" id="accordionLocation">
                                <li class="list-group-item px-0 py-3 mt-3 ">
                                    <div class="d-flex">
                                        <div class="me-2 mt-2">
                                            <div class="status bg-success"></div>
                                        </div>
                                        <div class="d-md-flex  justify-content-between w-100">
                                            <div class="d-flex">
                                                <i class="fe fe-airplay font-32"></i>
                                                <div class="ms-3">
                                                    <h5 class="mb-0">Nome do Computador, Localização, IP 102.250.233.120</h5>
                                                    <small> Sua sessão atual</small>
                                                    <p class="mb-0 font-14">Logado Neste Computador</p>
                                                </div>
                                            </div>
                                            <div>
                                                <a href="#" data-bs-toggle="collapse" data-bs-target="#locationOne" aria-expanded="true" aria-controls="locationOne" class="mt-3 mt-md-0 btn btn-light btn-sm">Detalhes</a>
                                            </div>
                                        </div>
                                    </div>

                                    <div id="locationOne" class="collapse show" data-bs-parent="#accordionLocation">
                                        <div class="card-body">
                                            <div class="mb-3">
                                                <h5 class="mb-0">Dispositivo:</h5>
                                                <span class="font-14">Windows, Mozilla FireFox</span>
                                            </div>
                                            <div class="mb-3">
                                                <h5 class="mb-0">Localização:</h5>
                                                <span class="font-14">Vitória de Santo Antão, Pernambuco, Brasil</span>
                                            </div>
                                            <div>
                                                <h5 class="mb-1">Acessado em:</h5>
                                                <p class="font-14 mb-0">21 de Janeiro de 2023</p>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                                {/* <!-- collapse two --> */}
                                <li class="list-group-item px-0 py-3 ">
                                    <div class="d-flex">
                                        <div class="me-2 mt-2">
                                            <div class="status bg-success"></div>
                                        </div>
                                        <div class="d-md-flex  justify-content-between w-100">
                                            <div class="d-flex">
                                                <i class="fe fe-smartphone font-32"></i>
                                                <div class="ms-3">
                                                    <h5 class="mb-0">Nome do Computador, Localização, IP 202.250.233.120</h5>
                                                    <small>Última Sessão</small>
                                                    <p class="mb-0 font-14">Visto em</p>
                                                </div>
                                            </div>
                                            <div>
                                                <a href="#" data-bs-toggle="collapse" data-bs-target="#locationTwo" aria-expanded="true" aria-controls="locationTwo" class="mt-3 mt-md-0 btn btn-light btn-sm">Detalhes</a>
                                            </div>
                                        </div>
                                    </div>

                                    <div id="locationTwo" class="collapse" data-bs-parent="#accordionLocation">
                                        <div class="card-body">
                                            <div class="mb-3">
                                                <h5 class="mb-0">Dispositivo:</h5>
                                                <span class="font-14">Windows, Google Chrome</span>
                                            </div>
                                            <div class="mb-3">
                                                <h5 class="mb-0">Localização:</h5>
                                                <span class="font-14">Rio de Janeiro, Rio de Janeiro, Brasil</span>
                                            </div>
                                            <div>
                                                <h5 class="mb-1">Acessado em:</h5>
                                                <p class="font-14 mb-0">21 de Janeiro de 2023</p>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                                {/* <!-- collapse three --> */}
                                <li class="list-group-item px-0 pt-3 pb-0 ">
                                    <div class="d-flex">
                                        <div class="me-2 mt-2">
                                            <div class="status bg-success"></div>
                                        </div>
                                        <div class="d-md-flex  justify-content-between w-100">
                                            <div class="d-flex">
                                                <i class="fe fe-tablet font-32"></i>
                                                <div class="ms-3">
                                                    <h5 class="mb-0">Nome do Computador, Localização, IP 302.250.233.120</h5>
                                                    <small>Última Sessão</small>
                                                    <p class="mb-0 font-14">Visto em</p>
                                                </div>
                                            </div>
                                            <div>
                                                <a href="#" data-bs-toggle="collapse" data-bs-target="#locationThree" aria-expanded="true" aria-controls="locationThree" class="mt-3 mt-md-0 btn btn-light btn-sm">Detalhes</a>
                                            </div>
                                        </div>
                                    </div>

                                    <div id="locationThree" class="collapse" data-bs-parent="#accordionLocation">
                                        <div class="card-body">
                                            <div class="mb-3">
                                                <h5 class="mb-0">Dispositivo:</h5>
                                                <span class="font-14">Linux, Opera</span>
                                            </div>
                                            <div class="mb-3">
                                                <h5 class="mb-0">Localização:</h5>
                                                <span class="font-14">Niterói, Rio de Janeiro, Brasil</span>
                                            </div>
                                            <div>
                                                <h5 class="mb-1">Acessado em::</h5>
                                                <p class="font-14 mb-0">21 de Janeiro de 2023</p>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    </>
  )
}
export default Seguranca;