import React from 'react';

function AboutCorp() {
    return (
        <>
            <div class="bg-cover" style={{
                backgroundImage: 'linear-gradient(180deg, rgba(214, 214, 214, 0.5) 0%, rgba(255, 255, 255, 0.1) 90.16%), url(../../img/Logo_Nova_Branca.svg)',
                backgroundSize: 'auto',
            }}>
                <div class="container">
                    <div class="row">
                        <div class="offset-lg-2 col-lg-8 col-md-12 col-12">
                            <div class="py-lg-20 py-12 text-white text-center">
                                <a class="popup-youtube icon-shape rounded-circle btn-play icon-xl
                                       text-decoration-none mb-3" href="https://www.youtube.com/watch?v=IWNzOLGRD9M">
                                    <i class="fas fa-play"></i>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="py-lg-16 py-6">
                <div class="container" style={{textAlign:'justify'}}>
                    <div class="row">
                        <div class="offset-lg-2 col-lg-8 col-md-12 col-12">
                            <h2 class="mb-4">Sobre Nós</h2>
                            <p>
                                A S & LIMA Consultoria e Desenvolvimento de Sistemas é uma empresa voltada a realizar a prestação de serviços de consultoria às empresas que procuram um software customizado sob medida, e que desejam melhorar suas práticas de gestão em seus processos, proporcionando a melhoria de seu desempenho empresarial.
                                A ideia da formação do grupo S & LIMA surgiu quando o seu fundador e atual CEO, Sullivan Lima, em sua busca por aperfeiçoamento, fazia o curso de <i>Green Belt Lean Six Sigma</i>. Durante seus estudos, Sullivan teve o <i>insight</i> de criar algo inovador que pudesse melhorar e personalizar cada empresa de maneira individual, ressaltando a identidade, considerando, principalmente, as particularidades, objetivos e cultura de cada uma delas.
                            </p>
                            <p>
                                Pensando na visão de nosso líder, além da consultoria, nós também oferecemos treinamentos aos profissionais que almejam uma atualização para o mercado de trabalho.
                                Vale destacar que ao realizar os nossos treinamentos, o aluno também poderá contar com uma assessoria para que possa aprender não apenas os conceitos referente ao curso escolhido, mas também como identificar os casos em que esses recursos poderão ser aplicados.
                            </p>
                            <p>
                                Ao escolher nossos serviços, você poderá contar com todo o suporte do nosso grupo de modo a criar vínculos para tirar dúvidas e construir um <i>networking</i> imprescindível aos dias atuais.
                                A seguir, mostraremos os norteadores que nos motivam a te entregar o melhor serviço a um preço justo e acessível:
                            </p>
                            <h4 class="mb-4">Nossa Missão</h4>
                            <p>
                                Temos como missão institucional entregar o serviço mais completo, com a máxima qualidade e dentro de um prazo que condiz com a realidade da sua necessidade.
                            </p>
                            <h4 class="mb-4">Nossa Visão</h4>
                            <p>
                                Nossa visão institucional é de nos tornarmos uma das maiores empresas de consultoria e desenvolvimento de software com atendimento espalhado por todo o Brasil.
                            </p>
                            <h4 class="mb-4">Nossos Valores</h4>
                            <p> Ética e Transparência</p><br />
                            <p> Empatia e Solidariedade</p><br />
                            <p> Responsabilidade</p><br />
                            <p> Honestidade</p><br />
                            <p> Justiça</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="pt-0 pb-3 ">
                <div class="container">
                    <div class="row">
                        <div class="offset-lg-1 col-lg-10 col-md-12 col-12 ">
                            <div class="mb-8 text-center">
                                <div class="mb-4">
                                    <i class="fab fa-instagram bg-light-secondary icon-shape icon-xl rounded text-primary font-28"></i>
                                </div>
                                <h2>Sigam-nos em nosso Instagram</h2>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="container-fluid">
                    <div class="d-flex">
                        <div class="w-25 me-2">
                            <img src="../assets/images/Modelo-Post-Instagram.jpg" alt="" class="w-100 rounded" />
                        </div>
                        <div class="w-25 me-2">
                            <img src="../assets/images/post-instagram.jpg" alt="" class="w-100 rounded" />
                        </div>
                        <div class="w-25 me-2">
                            <img src="../assets/images/post-instagram-coletivo.jpg" alt="" class="w-100 rounded" />
                        </div>
                        <div class="w-25 me-2">
                            <img src="../assets/images/post-instagram-2.jpg" alt="" class="w-100 rounded" />
                        </div>
                        <div class="w-25 me-2">
                            <img src="../assets/images/Modelo-Post-Instagram.jpg" alt="" class="w-100 rounded" />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default AboutCorp;