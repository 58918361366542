// OverviewComponent.jsx
function OverviewComponent({ serviceContent }) {
  return (
    <div className="tab-pane fade show active" id="pills-Overview" role="tabpanel" aria-labelledby="pills-Overview-tab">
      <div>
        <p className="text-dark lead">{serviceContent.intro}</p>

        {serviceContent.paragraphs.map((paragraph, index) => (
          <p key={index}>{paragraph}</p>
        ))}

        <h3 className="mb-4 mt-4">Com o que você contará? <i className="bi bi-emoji-smile"></i></h3>
        <div className="mb-4">
          <div className="row">
            <div className="col-lg-4 col-md-6 col-12">
              <ul className="list-unstyled">
                {serviceContent.services.map((service, idx) => (
                  <li key={idx}>
                    <i className="fas fa-check-circle me-2 font-13 text-success"></i>{service}
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
        <h3 className="mb-4 mt-4">Como será a metodologia ?</h3>
        <p className="mb-4">
          {serviceContent.methodology}
        </p>
        <a href="/login" className="btn btn-primary">Inscreva-se neste curso</a>
      </div>
    </div>
  );
}

export default OverviewComponent;
