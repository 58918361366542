import React from 'react'

function AlunosDizem(){
  return(
    // <!-- start testimonials -->
    <section class="bg-light left-slant-shape right-slant-shape py-14 py-lg-15 py-xxl-19 right-slant-shape">
      <div class="container">
        <div class="row g-4 g-lg-5 align-items-center">
      <div class="col-xl-7 order-2 order-xl-1">
				<div class="row mt-0 mt-xl-5">
					{/* <!-- Review --> */}
					<div class="col-md-7 position-relative mb-0 mt-0 mt-md-5">
						{/* <!-- SVG --> */}
						<figure class="fill-danger opacity-2 position-absolute top-0 start-0 translate-middle mb-3">
							<svg width="211px" height="211px">
								<path d="M210.030,105.011 C210.030,163.014 163.010,210.029 105.012,210.029 C47.013,210.029 -0.005,163.014 -0.005,105.011 C-0.005,47.015 47.013,-0.004 105.012,-0.004 C163.010,-0.004 210.030,47.015 210.030,105.011 Z" fill="#6a0dad"></path>
							</svg>
						</figure>

						<div class="bg-body shadow text-center p-4 rounded-3 position-relative mb-5 mb-md-0">
							{/* <!-- Content --> */}
							<blockquote>
								<p>
									<span class="me-1 small"><i class="fas fa-quote-left"></i></span>
									Sullivan é um profissional promissor que possui muitas habilidades nas áreas de exatas e na análise de dados. Sem dúvidas agregou muito conhecimento em minha jornada como profissional.
									<span class="ms-1 small"><i class="fas fa-quote-right"></i></span>
								</p>
							</blockquote>
							{/* <!-- Rating --> */}
							<ul class="list-inline mb-2">
								<li class="list-inline-item me-0 small"><i class="fas fa-star text-warning"></i></li>
								<li class="list-inline-item me-0 small"><i class="fas fa-star text-warning"></i></li>
								<li class="list-inline-item me-0 small"><i class="fas fa-star text-warning"></i></li>
								<li class="list-inline-item me-0 small"><i class="fas fa-star text-warning"></i></li>
								<li class="list-inline-item me-0 small"><i class="fas fa-star-half-alt text-warning"></i></li>
							</ul>
							{/* <!-- Info --> */}
							<h6 class="mb-0">Victor Aichi</h6>
						</div>
					</div>

					{/* <!-- Mentor list --> */}
					<div class="col-md-5 mt-5 mt-md-0 d-none d-md-block">
						<div class="bg-body shadow p-4 rounded-3 d-inline-block position-relative">
							{/* <!-- Icon --> */}
							<div class="icon-lg bg-warning rounded-circle position-absolute top-0 start-100 translate-middle">
								<i class="bi bi-shield-fill-check text-dark"></i>
							</div>
							{/* <!-- Title --> */}
              <p>
									<span class="me-1 small"><i class="fas fa-quote-left"></i></span> 
										Aquiri muito conhecimento sobre banco de dados relacionais e apliquei em minha empresa para melhores análises.
									<span class="ms-1 small"><i class="fas fa-quote-right"></i></span>
								</p>
							{/* <!-- Info --> */}
							<h6 class="mb-0">Luiz Campos</h6>
						</div>
					</div>
				{/* </div> <!-- Row END --> */}

				<div class="row mt-5 mt-xl-0">
					{/* <!-- Rating --> */}
					<div class="col-7 mt-0 mt-xl-5 text-end position-relative z-index-1 d-none d-md-block">
						{/* <!-- SVG --> */}
						<figure class="fill-danger position-absolute top-0 start-50 mt-n7 ms-6 ps-3 pt-2 z-index-n1 d-none d-lg-block">
							<svg enable-background="new 0 0 160.7 159.8" height="180px">
								<path d="m153.2 114.5c1.2 0 2.1 1 2.1 2.2s-1 2.2-2.1 2.2c-1.2 0-2.1-1-2.1-2.2-0.1-1.2 0.9-2.2 2.1-2.2z" fill="#6a0dad"></path>
								<path d="m116.4 114.5c1.2 0 2.1 1 2.1 2.2s-1 2.2-2.1 2.2c-1.2 0-2.1-1-2.1-2.2s0.9-2.2 2.1-2.2z" fill="#6a0dad"></path>
								<path d="m134.8 114.5c1.2 0 2.1 1 2.1 2.2s-1 2.2-2.1 2.2c-1.2 0-2.1-1-2.1-2.2s0.9-2.2 2.1-2.2z" fill="#6a0dad"></path>
								<path d="m135.1 96.9c1.2 0 2.1 1 2.1 2.2s-1 2.2-2.1 2.2c-1.2 0-2.1-1-2.1-2.2s0.9-2.2 2.1-2.2z" fill="#6a0dad"></path>
								<path d="m153.5 96.9c1.2 0 2.1 1 2.1 2.2s-1 2.2-2.1 2.2c-1.2 0-2.1-1-2.1-2.2-0.1-1.2 0.9-2.2 2.1-2.2z" fill="#6a0dad"></path>
								<path d="m98.3 96.9c1.2 0 2.1 1 2.1 2.2s-1 2.2-2.1 2.2c-1.2 0-2.1-1-2.1-2.2s0.9-2.2 2.1-2.2z" fill="#6a0dad"></path>
								<ellipse cx="116.7" cy="99.1" rx="2.1" ry="2.2" fill="#6a0dad"></ellipse>
								<path d="m153.2 149.8c1.2 0 2.1 1 2.1 2.2s-1 2.2-2.1 2.2c-1.2 0-2.1-1-2.1-2.2-0.1-1.3 0.9-2.2 2.1-2.2z" fill="#6a0dad"></path>
								<path d="m135.1 132.2c1.2 0 2.1 1 2.1 2.2s-1 2.2-2.1 2.2c-1.2 0-2.1-1-2.1-2.2 0-1.3 0.9-2.2 2.1-2.2z" fill="#6a0dad"></path>
								<path d="m153.5 132.2c1.2 0 2.1 1 2.1 2.2s-1 2.2-2.1 2.2c-1.2 0-2.1-1-2.1-2.2-0.1-1.3 0.9-2.2 2.1-2.2z"fill="#6a0dad"></path>
								<path d="m80.2 79.3c1.2 0 2.1 1 2.1 2.2s-1 2.2-2.1 2.2c-1.2 0-2.1-1-2.1-2.2s1-2.2 2.1-2.2z"fill="#6a0dad"></path>
								<path d="m117 79.3c1.2 0 2.1 1 2.1 2.2s-1 2.2-2.1 2.2c-1.2 0-2.1-1-2.1-2.2s0.9-2.2 2.1-2.2z" fill="#6a0dad"></path>
								<path d="m98.6 79.3c1.2 0 2.1 1 2.1 2.2s-1 2.2-2.1 2.2c-1.2 0-2.1-1-2.1-2.2s0.9-2.2 2.1-2.2z" fill="#6a0dad"></path>
								<path d="m135.4 79.3c1.2 0 2.1 1 2.1 2.2s-1 2.2-2.1 2.2c-1.2 0-2.1-1-2.1-2.2s0.9-2.2 2.1-2.2z" fill="#6a0dad"></path>
								<path d="m153.8 79.3c1.2 0 2.1 1 2.1 2.2s-1 2.2-2.1 2.2c-1.2 0-2.1-1-2.1-2.2s0.9-2.2 2.1-2.2z" fill="#6a0dad"></path>
								<path d="m80.6 61.7c1.2 0 2.1 1 2.1 2.2s-1 2.2-2.1 2.2c-1.2 0-2.1-1-2.1-2.2-0.1-1.2 0.9-2.2 2.1-2.2z" fill="#6a0dad"></path>
								<ellipse cx="98.9" cy="63.9" rx="2.1" ry="2.2" fill="#6a0dad"></ellipse>
								<path d="m117.3 61.7c1.2 0 2.1 1 2.1 2.2s-1 2.2-2.1 2.2c-1.2 0-2.1-1-2.1-2.2s0.9-2.2 2.1-2.2z" fill="#6a0dad"></path>
								<ellipse cx="62.2" cy="63.9" rx="2.1" ry="2.2" fill="#6a0dad"></ellipse>
								<ellipse cx="154.1" cy="63.9" rx="2.1" ry="2.2" fill="#6a0dad"></ellipse>
								<path d="m135.7 61.7c1.2 0 2.1 1 2.1 2.2s-1 2.2-2.1 2.2c-1.2 0-2.1-1-2.1-2.2s0.9-2.2 2.1-2.2z" fill="#6a0dad"></path>
								<path d="m154.4 44.1c1.2 0 2.1 1 2.1 2.2s-1 2.2-2.1 2.2c-1.2 0-2.1-1-2.1-2.2s0.9-2.2 2.1-2.2z" fill="#6a0dad"></path>
								<path d="m80.9 44.1c1.2 0 2.1 1 2.1 2.2s-1 2.2-2.1 2.2c-1.2 0-2.1-1-2.1-2.2-0.1-1.2 0.9-2.2 2.1-2.2z" fill="#6a0dad"></path>
								<path d="m44.1 44.1c1.2 0 2.1 1 2.1 2.2s-1 2.2-2.1 2.2c-1.2 0-2.1-1-2.1-2.2-0.1-1.2 0.9-2.2 2.1-2.2z" fill="#6a0dad"></path>
								<path d="m99.2 44.1c1.2 0 2.1 1 2.1 2.2s-1 2.2-2.1 2.2c-1.2 0-2.1-1-2.1-2.2s1-2.2 2.1-2.2z" fill="#6a0dad"></path>
								<ellipse cx="117.6" cy="46.3" rx="2.1" ry="2.2" fill="#6a0dad"></ellipse>
								<path d="m136 44.1c1.2 0 2.1 1 2.1 2.2s-1 2.2-2.1 2.2c-1.2 0-2.1-1-2.1-2.2s0.9-2.2 2.1-2.2z" fill="#6a0dad"></path>
								<path d="m62.5 44.1c1.2 0 2.1 1 2.1 2.2s-1 2.2-2.1 2.2c-1.2 0-2.1-1-2.1-2.2-0.1-1.2 0.9-2.2 2.1-2.2z" fill="#6a0dad"></path>
								<path d="m154.7 26.5c1.2 0 2.1 1 2.1 2.2s-1 2.2-2.1 2.2c-1.2 0-2.1-1-2.1-2.2s0.9-2.2 2.1-2.2z" fill="#6a0dad"></path>
								<path d="m62.8 26.5c1.2 0 2.1 1 2.1 2.2s-1 2.2-2.1 2.2c-1.2 0-2.1-1-2.1-2.2-0.1-1.2 0.9-2.2 2.1-2.2z" fill="#6a0dad"></path>
								<ellipse cx="136.3" cy="28.6" rx="2.1" ry="2.2" fill="#6a0dad"></ellipse>
								<path d="m99.6 26.5c1.2 0 2.1 1 2.1 2.2s-1 2.2-2.1 2.2c-1.2 0-2.1-1-2.1-2.2-0.1-1.2 0.9-2.2 2.1-2.2z" fill="#6a0dad"></path>
								<path d="m117.9 26.5c1.2 0 2.1 1 2.1 2.2s-1 2.2-2.1 2.2c-1.2 0-2.1-1-2.1-2.2s1-2.2 2.1-2.2z" fill="#6a0dad"></path>
								<path d="m81.2 26.5c1.2 0 2.1 1 2.1 2.2s-1 2.2-2.1 2.2c-1.2 0-2.1-1-2.1-2.2-0.1-1.2 0.9-2.2 2.1-2.2z" fill="#6a0dad"></path>
								<path d="m26 26.5c1.2 0 2.1 1 2.1 2.2s-1 2.2-2.1 2.2c-1.2 0-2.1-1-2.1-2.2s0.9-2.2 2.1-2.2z" fill="#6a0dad"></path>
								<ellipse cx="44.4" cy="28.6" rx="2.1" ry="2.2"fill="#6a0dad"></ellipse>
								<path d="m136.6 13.2c-1.2 0-2.1-1-2.1-2.2s1-2.2 2.1-2.2c1.2 0 2.1 1 2.1 2.2 0.1 1.2-0.9 2.2-2.1 2.2z" fill="#6a0dad"></path>
								<path d="m155 13.2c-1.2 0-2.1-1-2.1-2.2s1-2.2 2.1-2.2c1.2 0 2.1 1 2.1 2.2 0.1 1.2-0.9 2.2-2.1 2.2z" fill="#6a0dad"></path>
								<path d="m26.3 13.2c-1.2 0-2.1-1-2.1-2.2s1-2.2 2.1-2.2c1.2 0 2.1 1 2.1 2.2s-0.9 2.2-2.1 2.2z" fill="#6a0dad"></path>
								<path d="m81.5 13.2c-1.2 0-2.1-1-2.1-2.2s1-2.2 2.1-2.2c1.2 0 2.1 1 2.1 2.2s-0.9 2.2-2.1 2.2z" fill="#6a0dad"></path>
								<path d="m63.1 13.2c-1.2 0-2.1-1-2.1-2.2s1-2.2 2.1-2.2c1.2 0 2.1 1 2.1 2.2s-0.9 2.2-2.1 2.2z" fill="#6a0dad"></path>
								<path d="m44.7 13.2c-1.2 0-2.1-1-2.1-2.2s1-2.2 2.1-2.2c1.2 0 2.1 1 2.1 2.2s-0.9 2.2-2.1 2.2z" fill="#6a0dad"></path>
								<path d="m118.2 13.2c-1.2 0-2.1-1-2.1-2.2s1-2.2 2.1-2.2c1.2 0 2.1 1 2.1 2.2 0.1 1.2-0.9 2.2-2.1 2.2z" fill="#6a0dad"></path>
								<path d="m7.9 13.2c-1.2 0-2.1-1-2.1-2.2s1-2.2 2.1-2.2c1.2 0 2.1 1 2.1 2.2 0.1 1.2-0.9 2.2-2.1 2.2z" fill="#6a0dad"></path>
								<path d="m99.9 13.2c-1.2 0-2.1-1-2.1-2.2s1-2.2 2.1-2.2c1.2 0 2.1 1 2.1 2.2s-1 2.2-2.1 2.2z" fill="#6a0dad"></path>
							</svg>
						</figure>
					</div>

					{/* <!-- Review --> */}
					<div class="col-md-5 mt-n6 mb-0 mb-md-5">
						<div class="bg-body shadow text-center p-4 rounded-3">
							{/* <!-- Content --> */}
							<blockquote>
								<p>
									<span class="me-1 small"><i class="fas fa-quote-left"></i></span> 
										Contratamos um projeto de BI com que fez a velocidade da informação ser apresentada muito mais rápida e precisa.
									<span class="ms-1 small"><i class="fas fa-quote-right"></i></span>
								</p>
							</blockquote>
							{/* <!-- Info --> */}
							<h6 class="mb-0">Empresa Ondunorte</h6>
						</div>
					</div>
				</div>
			</div>
      </div>
      <div class="col-xl-5 order-1 text-center text-xl-start">
				{/* <!-- Title --> */}
				<h2 class="fs-1">O que nossos clientes dizem sobre nossos serviços...</h2>
        <p className='mb-12'>Qualidade é nosso lema! Acompanhem o que nossos clientes, alunos e interessados em nossos serviços dizem sobre nós nas redes sociais.</p>
			</div></div>
      </div>
    </section>
    
    // <!-- end of testmonials -->
  )
}
export default AlunosDizem;