import React, { useState, } from 'react';
import { useNavigate } from 'react-router-dom';



function Cadastro() {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    nome: '',
    email: '',
    senha: ''
  });


  const handleInputChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };


  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch('http://localhost:5000/api/cadastro', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(formData)
      });
      if (response.ok) {
        navigate('/customerProfile'); // Redirecionar para a página de sucesso após o cadastro
      } else {
        // Tratar erros de resposta aqui, se necessário
      }
    } catch (error) {
      console.error('Erro ao enviar solicitação:', error);
    }
  };
  return (
    <div className="min-vh-100 d-flex align-items-center" style={{ background: '#d6d6d6' }}>
      <div className="container">
        <div className="row">
          <div className="offset-lg-3 col-lg-6 col-12 mb-4">
            <a href="/" className="d-flex justify-content-center">
              <img src="/img/Frame.svg" width="50%" alt="" />
            </a>
            <div className="bg-dark-gradient-c p-4 p-lg-8 rounded-3">
              <form onSubmit={handleSubmit} method='POST'>
                <h1 className="mb-2 text-white">Bem-Vindo!</h1>
                <p className="mb-4 text-white">Preencha os campos abaixo e realize seu cadastro</p>
                <div className="mb-3">
                  <label htmlFor="nome" className="form-label text-white-50">Nome</label>
                  <input type="text" id="nome" name="nome" className="form-control border-0" placeholder="Nome" required value={formData.nome} onChange={handleInputChange} />
                </div>
                <div className="mb-3">
                  <label htmlFor="email" className="form-label text-white-50">Email</label>
                  <input type="email" id="email" name="email" className="form-control border-0" placeholder="Endereço de E-mail" required value={formData.email} onChange={handleInputChange} />
                </div>
                <div className="mb-3 mb-4">
                  <label htmlFor="senha" className="form-label text-white-50">Cadastre sua senha</label>
                  <input type="password" id="senha" name="senha" className="form-control border-0" placeholder="Senha de Acesso" required value={formData.senha} onChange={handleInputChange} />
                </div>
                <div className="d-grid">
                  <button className="btn btn-primary" type="submit">Realizar Cadastro</button>
                </div>
                <div className="d-lg-flex justify-content-between mt-4 mb-2">
                  <p className="text-muted font-14">Possui conta? <a href="/login" className="text-white btn-destac">Acesse Aqui</a></p>
                </div>
              </form>
              <p id="response-message"></p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Cadastro;
