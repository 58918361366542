import React from 'react'

function QuestionsClassrom(){
  return(
    <>
          {/* <!-- faq --> */}
          <div class="mb-4">
        <h3 class="mb-4 text-white">Perguntas Frequentes</h3>
        <div class="mb-5 text-muted">
          <h4 class="h5 text-white">Os cursos geram certificados?</h4>
          <p>
            Sim. Todas os nossos cursos, palestras e/ou workshops concedem ao ouvinte
            certificado de participação com o quantitativo das horas, tema da palestra
            e assinatura do CEO da nossa instituição.
          </p>
        </div>
        <div class="mb-5 text-muted">
          <h4 class="h5 text-white">Questões relacionadas a perguntas frequentes</h4>
          <p>
            Não. Nós apenas cobramos valores referentes a inscrição e participação do evento
            com valores acessíveis a todos os interessados.
            Lembrando que aceitamos todas as formas de pagamento.
          </p>
        </div>
        <div class="mb-5 text-muted">
          <h4 class="h5 text-white">Os conteúdos em vídeo ficam gravados por tempo indeterminado?</h4>
          <p>
            Sim. Todos os nossos vídeos ficam disponibilizados por prazo indeterminado, para sempre que desejar poder assistir novamente.
          </p>
        </div>
      </div>
    </>
  )
}

export default QuestionsClassrom;