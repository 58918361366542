import React from 'react';

function MenuProfile(){
  return(
<>
                <div class="col-lg-3 col-md-4 col-12">
                    <nav class="navbar navbar-expand-md navbar-light  dashboard-nav mb-3 mb-lg-0 ">
                        <a class="d-xl-none d-lg-none d-md-none text-inherit  fw-bold" href="#">Menu</a>
                        <button class="navbar-toggler d-md-none icon-shape icon-sm rounded bg-primary text-light" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
                            <span class="fas fa-bars"></span>
                        </button>
                        <div class="collapse navbar-collapse" id="navbarNavDropdown">
                            <div class="navbar-nav flex-column w-100 ">
                                <div class="border-bottom py-4 p-md-4 d-flex justify-content-between text-reset">
                                    <div class="d-flex align-items-center">
                                        <img src="/img/Me-About.jpeg" alt="" class="rounded-circle avatar-lg" />
                                        <div class="ms-3 lh-1">
                                            <h5 class="mb-1">Sullivan Lima</h5>
                                            <small>Membro Gratuito</small>
                                        </div>
                                    </div>
                                </div>
                                <div class="py-4 p-md-4">
                                    <span class="heading ">Minha Conta</span>
                                    <ul class="list-unstyled mb-4 mt-2">
                                        <li class="nav-item active">
                                            <a class="nav-link" href="/customerProfile">Meu Perfil</a>
                                        </li>
                                        <li class="nav-item ">
                                            <a class="nav-link" href="/assinatura">Assinaturas</a>
                                        </li>
                                        <li class="nav-item ">
                                            <a class="nav-link" href="/seguranca">Segurança</a>
                                        </li>
                                        <li class="nav-item ">
                                            <a class="nav-link" href="/notificacoes">Notificações</a>
                                        </li>
                                        <li class="nav-item ">
                                            <a class="nav-link" href="/usuarios">Usuários</a>
                                        </li>
                                    </ul>
                                    <span class="heading border-top pt-4 d-block">Cobranças</span>

                                    <ul class="list-unstyled mb-0">
                                        <li class="nav-item ">
                                            <a class="nav-link" href="dashboard-payment-plan.html">Planos e Pagamentos</a>
                                        </li>
                                        <li class="nav-item">
                                            <a class="nav-link" href="dashboard-payment-history.html">Histórico de Pagamento</a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </nav>
                </div>

</>
  )
}

export default MenuProfile;