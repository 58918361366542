import React from 'react'


function Parallax(){
  return(
<div class="section section-cards">
<div class="container">
<div class="row">
<div class="col-md-7">
<div class="image-container-2">
<img class="img" src="/img/cards-test.webp"/>
</div>
</div>
<div class="col-md-4 ml-auto">
<div class="section-description">
<h3 class="display-6">Interface Intuitiva, Amigável e Moderna</h3>

<p >Sua parceira em consultoria empresarial e desenvolvimento de softwares personalizados começa com a S & LIMA. Nós somos a empresa indicada para te ajudar a alcançar o sucesso, a lucratividade e a excelência corporativa por meio de soluções tecnológicas inovadoras e sob medida. Desenvolvimento de softwares personalizados para atender às necessidades únicas do seu negócio.</p>
</div>
</div>
</div>
</div>
</div>
  )
}

export default Parallax;