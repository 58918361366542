import React from 'react';
import AppRoutes from './routes';
import { bootstrapMin } from './utils/bootstrap.min';
import './css/style.css';
import './components/about/PersonalChief.css';
import './imports'; // Importa o arquivo imports.js

bootstrapMin();

const App = () => {
  return (
    <div className="App">
      <AppRoutes />
    </div>
  );
};

export default App;
