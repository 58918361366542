import React from 'react';

function PersonalChief({ nome, description, foto, texto }) {
  return (
  <div class="container">
    <div class="row">
      <div class="col-lg-4 col-md-4 col-12">
        <div class="mb-6 image-container">
          <div class="flip-card-front">
            <img src={foto} alt="" class="img-fluid w-100 mb-3 rounded-3" />
              <h4 class="mb-0"><a href="#" class="text-primary">{nome}</a></h4>
                <span class="font-14">{description}</span>
          </div>
        </div>
    </div>
    <div class="col-lg-8 col-md-8 col-12">
            <h4 class="mb-2 mt-8 display-6">Somos Pessoas Para Pessoas</h4>
          <figure class="mb-5 text-center">
            <blockquote class="blockquote blockquote-lg">"Uma empresa é como uma máquina onde cada engrenagem se move harmoniosamente em sincronia para impulsionar o sucesso. O verdadeiro coração de uma empresa reside nas pessoas que a compõe, nas parcerias que são feitas e nos aprendizados contínuos obtidos pela troca entre colaboradores <i>versus</i> parceiros. Assim, o atendimento humanizado, a transformação de sua ideia em realidade tecnológica é todo o sangue que pulsa nosso coração e transforma um objetivo empresarial nas necessidades individuais de cada pessoa empresária ou não. Essa é a chave para fortalecer os laços e construir relacionamentos sólidos. Sejam bem-vindos à S & LIMA onde todo o sucesso é fruto das parcerias construídas com nossos clientes."
            </blockquote></figure>
            </div>
    </div>

  </div>


  );
}

export default PersonalChief;

