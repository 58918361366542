import React from 'react';


function Header() {
  const scrollToSection = (id) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <header className="header border-4 border-top border-primary">
      <div className="container">
        <nav className="navbar navbar-expand-lg navbar-default">
          <a className="navbar-brand" href="/">
            <img src="/img/Frame.svg" width="220" alt="" />
          </a>
          <button
            className="navbar-toggler collapsed"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbar-default"
            aria-controls="navbar-default"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="icon-bar top-bar mt-0"></span>
            <span className="icon-bar middle-bar"></span>
            <span className="icon-bar bottom-bar"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbar-default">
            <button
              className="navbar-toggler collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbar-default"
              aria-controls="navbar-default"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <i className="fas fa-times"></i>
            </button>
            <ul className="navbar-nav ms-auto me-lg-3 ">
              <li className="nav-item dropdown disabled">
                <a className="nav-link d-lg-none" href="#">
                  Menu
                </a>
              </li>
              <li className="nav-item dropdown ">
                <a
                  className="nav-link dropdown-toggle"
                  href="#"
                  id="menu-1"
                  data-bs-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                  data-bs-display="static"
                >
                  <i className="fa fa-handshake"></i>
                  Quem Somos
                </a>
                <ul
                  className="dropdown-menu dropdown-menu-arrow "
                  aria-labelledby="menu-1"
                >
                  <li>
                    <a className="dropdown-list-group-item " href="/team">
                      <h4 className="h5">Nosso Time</h4>
                      <p className="text-muted mb-0 font-12">
                        Conheça nosso time de consultores
                      </p>
                    </a>
                  </li>
                  <li>
                    <a className="dropdown-list-group-item " href="/aboutcorp">
                      <h4 className="h5">Sobre a Empresa</h4>
                      <p className="text-muted mb-0 font-12">
                        Conheça a história da nossa instituiçao
                      </p>
                    </a>
                  </li>
                </ul>
              </li>
                            <li className="nav-item dropdown ">
                <a className="nav-link" id="menu-2" href="#nossosServicos" onClick={() => scrollToSection('nossos-cursos')}>
                  <i className="fa fa-graduation-cap"></i>
                  Nossos Serviços
                </a>
              </li>
              
             <li className="nav-item dropdown ">
                <a className="nav-link" id="menu-2" href="/nossoscursos" onClick={() => scrollToSection('nossos-cursos')}>
                  <i className="fa fa-graduation-cap"></i>
                  Nossos Cursos
                </a>
              </li> 
               {/* <li className="nav-item dropdown">
              <a className="nav-link dropdown-toggle" href="/login" id="menu-5" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <i className="fas fa-user"></i>
                Meu Acesso
              </a>
                <ul
                  className="dropdown-menu dropdown-menu-arrow  dropdown-menu-xl-start "
                  aria-labelledby="menu-5"
                >
                  <li className="dropdown-submenu ">
                    <a className="dropdown-item" href="/login">
                      Acessar
                    </a>
                  </li>
                  <li className="dropdown-submenu ">
                    <a
                      className="dropdown-item"
                      href="./pages/password-reset.html"
                    >
                      Redefinir Senha
                    </a>
                  </li>
                </ul>
              </li>  */}
            </ul>
          </div>
        </nav>
        <form className="d-flex">
          <input
            className="form-control me-2"
            type="search"
            placeholder="Pesquisar"
            aria-label="Search"
            id="search"
          />
        </form>
      </div>
    </header>
  );
}

export default Header;
