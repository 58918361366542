import React from 'react';

function Afiliado({nomeAfiliado, profissaoAfiliado, imagemAfiliado}){
  return (<>
                  {/* Afiliados */}
                  <div class="col-lg-3 col-md-3 col-12">
                  <div class="mb-6">
                <img src={imagemAfiliado} alt="" class="img-fluid w-100 mb-3 rounded-3 " />

                <h4 class="mb-0">
                  {nomeAfiliado}
                </h4>
                <span class="font-14">{profissaoAfiliado}</span>
              </div>
            </div>
  </>)
}

export default Afiliado;