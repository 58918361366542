import React from 'react'


function ServicosSection(){
  return(
    <section class="bg-dark left-slant-shape right-slant-shape py-14 py-lg-15 py-xxl-19 right-slant-shape" id='nossosServicos'>
    <div class="container">
      <div class="row">
      <div class="inner-container-small text-center">
            <h2 class="mb-0 mt-4 text-white px-4 py-2" >Consultoria, Desenvolvimento de softwares e Treinamento</h2>
            <h4 class="mb-0 mt-4 lead">
            Explore nossos serviços de consultoria e desenvolvimento de Softwares personalizados que garantirão o sucesso do seu negócio. 
            </h4>
          </div>

          {/* <!-- Service list START --> */}
          <div class="row row-cols-1 row-cols-sm-2 row-cols-lg-3 row-cols-xl-4 gy-5 gy-md-7 mt-3">
            {/* <!-- Service item --> */}
            <div class="col">
              <div class="card bg-light h-100">
                <div class="card-body pb-0">
                  {/* <!-- Icon --> */}
                    <span class="display-6 text-center text-blue mb-0">
                    <div class="icon-xl bg-white text-primary rounded-circle mb-4 mt-n5">
                    <i class="fas fa-chart-line"></i>
                  </div></span>
                  {/* <!-- Content --> */}
                  <h5 class="mb-3">Business Intelligence</h5>
                  <p> Tenha um olhar apurado sobre dados. Potencialize suas tomadas de decisões com nosso projetos de BI.</p>
                </div>
                <div class="card-footer bg-light mt-auto pt-2">
                  <a class="icon-link icon-link-hover" href="/bi-services">Saiba Mais...<i class="bi bi-arrow-right"></i> </a>
                </div>
              </div>
            </div>

            {/* <!-- Service item --> */}
            <div class="col">
              <div class="card bg-light h-100">
                <div class="card-body pb-0">
                  {/* <!-- Icon --> */}
                  <span class="display-6 text-center text-blue mb-0">
                  <div class="icon-xl bg-white text-primary rounded-circle mb-4 mt-n5">
                    <i class="fa fa-pen-square"></i>
                  </div></span>
                  {/* <!-- Content --> */}
                  <h5 class="mb-3">Planejamento Estratégico</h5>
                  <p>Conduza sua empresa rumo ao sucesso com um planejamento aderente, estratégico e perspicaz.</p>
                </div>
                <div class="card-footer bg-light mt-auto pt-2">
                  <a class="icon-link icon-link-hover" href="/planejamento">Saiba Mais...<i class="bi bi-arrow-right"></i> </a>
                </div>
              </div>
            </div>
            {/* <!-- Service item --> */}
            <div class="col">
              <div class="card bg-light h-100">
                <div class="card-body pb-0">
                  {/* <!-- Icon --> */}
                  <span class="display-6 text-center text-blue mb-0">
                  <div class="icon-xl bg-white text-primary rounded-circle mb-4 mt-n5">
                    <i class="fa fa-globe"></i>
                  </div></span>
                  {/* <!-- Content --> */}
                  <h5 class="mb-3">Desenvolvimento Web</h5>
                  <p>Explore as fronteiras da tecnologia. O mundo online é para você que almeja presença marcante.</p>
                </div>
                <div class="card-footer bg-light mt-auto pt-2">
                  <a class="icon-link icon-link-hover" href="/portifolio">Saiba Mais...<i class="bi bi-arrow-right"></i> </a>
                </div>
              </div>
            </div>

            {/* <!-- Service item --> */}
            <div class="col">
              <div class="card bg-light h-100">
                <div class="card-body pb-0">
                  {/* <!-- Icon --> */}
                  <span class="display-6 text-center text-blue mb-0">
                  <div class="icon-xl bg-white text-primary rounded-circle mb-4 mt-n5">
                    <i class="fa fa-network-wired"></i>
                  </div></span>
                  {/* <!-- Content --> */}
                  <h5 class="mb-3">Gestão de Processos</h5>
                  <p>Otimize e refina o que você faz. Processo é melhoria continua, seja o melhor todos os dias, amadureça seus processos.</p>
                </div>
                <div class="card-footer bg-light mt-auto pt-2">
                  <a class="icon-link icon-link-hover" href="/process">Saiba Mais...<i class="bi bi-arrow-right"></i> </a>
                </div>
              </div>
            </div>
          </div>
      </div>
    </div>
  </section>

  )
}

export default ServicosSection;