// InstructorComponent.jsx
function DadosInstrutor({ instructor }) {
  return (
    <div className="tab-pane fade" id="pills-Coach" role="tabpanel" aria-labelledby="pills-Coach-tab">
      <div className="card">
        <div className="card-body p-4">
          <div className="d-flex align-items-center mb-4">
            <img src={instructor.image} width="80" height="75" alt="" className="rounded-circle me-3" />
            <div className="lh-1">
              <h4 className="mb-1">{instructor.name}</h4>
              <p className="mb-0">{instructor.description}</p>
            </div>
          </div>
          <h5 className="mb-4">Sobre o Instrutor</h5>
          <p>
            {instructor.description_2}
          </p>
          <p className="mb-0">{instructor.description_3}</p>
        </div>
      </div>
    </div>
  );
}

export default DadosInstrutor;
