import React from 'react'
import { detectOverflow } from '@popperjs/core'
import {bootstrapMin} from '../../utils/bootstrap.min'
import SideBarComponent from './sidebarcourse'
import DadosInstrutor from './dadosInstrutor'
import Ementa from './ementa'
import OverviewComponent from './overviewcourse'
import {BundleMin} from '../../utils/bootstrap.bundle.min';

const courses = [
  {
    title: "Microsoft Power BI (Básico)",
    overviewContent: {
      intro: "A compreensão das ferramentas de análise de dados é crucial na era atual, especialmente no contexto dos bancos de dados relacionais e da linguagem SQL. O Microsoft Power BI destaca-se como uma ferramenta acessível e poderosa, aprimorando significativamente a construção de consultas e análises para profissionais em diferentes setores.",
      paragraphs: [
        "Acreditamos que conhecer ferramentas para análise de dados é imprescindível nos dias atuais, principalmente, o Microsoft Power BI. A ferramenta é simples de ser utilizada e otimiza muito na construção de gráficos e nas tomadas de decisões de gestores das mais variadas organizações. Todos podem construir e utilizar os painéis com informações gerenciais.",
        "Assim, nós trabalhamos com dados reais e exemplos relacionados às diversas área de negócios, tais quais: Financeira, Recursos Humanos, Compras e Suprimentos, etc. De modo a projetar em você uma aprendizagem particpativa motivacional para garantir que seu desenvolvimento seja o melhor para atingir esses objetivos."
      ],
      services: [
        "Transformar Dados",
        "Criar Dashboards",
        "Criar Relacionamentos",
        "Tipos de Dados",
        "e mais..."
      ],
      methodology: "Visamos e construimos um ensino voltado às realidades práticas e compatíveis com situações reais do mercado de trabalho de trabalho. Realizaremos também exercícios para que possam absover o conteúdo introduzido, com as devidas correções. Não trabalhamos com a prática de avaliações que balizem você por meio de nota, porém colocamos algumas questões teóricas para auxiliar na absorção do conhecimento."
    },
    ementaModules: [
      {
        title: "Conceitos Introdutórios",
        moduleNumber: "1ª Módulo",
        topics: [
          { title: "Conhecendo o Microsoft Power BI", duration: "1h" },
          { title: "Instalando e Configurando o Microsoft Power BI", duration: "1h" },
          { title: "Iniciando os Primeiros Passos", duration: "1h" },
          { title: "Realizandos os Primeiros Tratamentos de Dados", duration: "1h" }
        ]
      },
      {
        title: "Trabalhando com Editor Power Query",
        moduleNumber: "2º Módulo",
        topics: [
          { title: "Noções Introdutórias", duration: "1h" },
          { title: "Transformação de Dados", duration: "1h" },
          { title: "Trabalhando com Colunas", duration: "1h" },
          { title: "Ferramentas e Ajuda", duration: "1h" }
        ]
      },
      {
        title: "Relacionamentos de Dados Entre Tabelas",
        moduleNumber: "3º Módulo",
        topics: [
          { title: "Conceitos de Modelagem de Dados", duration: "1h" },
          { title: "Criando Suas Relações e Modelo de Dados", duration: "1h" },
          { title: "Início de Algumas Funções DAX (Data Analysis Expressions", duration: "1h" },
          { title: "Criando Seus Primeiros Painéis - Dashboards", duration: "1h" }
        ]
      },
      {
        title: "Projetos de Banco de Dados",
        moduleNumber: "4ª Módulo",
        topics: [{ title: "Projetos de Power BI", duration: "8h" }]
      },
    ],
    instructor: {
      name: 'Sullivan Lima',
      description: 'Administrador, Consultor em Gestão de Processos e Analista de Sistemas',
      image: '/img/Me-About.jpeg',
      description_2: 'Sullivan Lima é formado em Administração e em Análise e Desenvolvimento de Sistemas; Especialista em Gestão de Negócios e Gestão de Projetos; e CEO e Fundador da S & LIMA Consultoria e Assessoria. Possui mais de 10 anos de experiência atuando na melhoria e no mapeamento de processos referentes às áreas de suprimentos, administrativa e financeira.',
      description_3: 'Seu foco é gestão de processos orientada a dados. Possui sólidos conhecimentos em Business Analytics referente a construção de modelos relacionais e geração de relatórios para tomada de decisão.'
    },
    sideBarContent: {
      price: 'R$ 500,00',
      instructorName: 'Sullivan Lima',
      duration: '20 horas',
      numberOfStudents: 'XX'
    }
  },
  {
    title: "Banco de Dados",
    overviewContent: {
      intro: "A compreensão das ferramentas de análise de dados é crucial na era atual, especialmente no contexto dos bancos de dados relacionais e da linguagem SQL. O Microsoft Power BI destaca-se como uma ferramenta acessível e poderosa, aprimorando significativamente a construção de consultas e análises para profissionais em diferentes setores.",
      paragraphs: [
        "O campo do Business Intelligence avança rapidamente, e aqui, nosso foco será estabelecer conceitos fundamentais e apresentar os cenários cruciais que envolvem temas relevantes nesse domínio dinâmico. Acreditamos que o BI é uma peça fundamental nos dias de hoje, permeando sistemas corporativos e contribuindo significativamente para a tomada de decisões.",
        "Ao desvendar as ferramentas voltadas para a área de Business Intelligence, visamos simplificar a compreensão e utilização da informação, otimizando processos, consultas e manipulação de dados. Nosso compromisso é ir além, aplicar conceitos e ferramentas em contextos reais relacionados a diversas áreas de negócios, como Financeira, Recursos Humanos, Compras e Suprimentos, entre outras. Essa abordagem prática e participativa visa não apenas transmitir conhecimento, mas também motivar sua participação ativa, garantindo que seu desenvolvimento seja robusto e alinhado aos objetivos estratégicos de sua empresa. Estamos aqui para moldar sua visão em Business Intelligence e impulsionar seu sucesso."
      ],
      services: [
        "Tratamento dos Dados",
        "Dashboards",
        "Cenários",
        "e mais..."
      ],
      methodology: "Nossa metodologia é cuidadosamente projetada para oferecer uma experiência de aprendizado prático e alinhada com as demandas do mercado de trabalho. Através de uma combinação de exposição a situações reais, exercícios práticos e correções personalizadas, os alunos têm a oportunidade de absorver o conteúdo de forma eficaz. Abordamos questões teóricas não como uma forma de avaliação, mas sim como ferramentas auxiliares para a compreensão e consolidação do conhecimento. Acreditamos que essa abordagem proporciona um ambiente de aprendizado dinâmico e estimulante, preparando os alunos para enfrentar os desafios do mundo profissional com confiança e habilidade."
    },
    ementaModules: [
      {
        title: "Conceitos Introdutórios",
        moduleNumber: "1ª Módulo",
        topics: [
          { title: "Introdução a Banco de Dados", duration: "1h" },
          { title: "Conceito de SGBDs", duration: "1h" },
          { title: "Estrutura de Banco de Dados", duration: "1h" },
          { title: "Normalização de Banco de Dados", duration: "1h" }
        ]
      },
      {
        title: "Modelagem de Banco de Dados",
        moduleNumber: "2º Módulo",
        topics: [
          { title: "Modelagem Conceitual", duration: "1h" },
          { title: "Modelagem Lógica", duration: "1h" },
          { title: "Modelagem Física", duration: "1h" },
          { title: "Projetos de Modelagem", duration: "1h" }
        ]
      },
      {
        title: "Linguagem SQL",
        moduleNumber: "3º Módulo",
        topics: [
          { title: "Funções de Criação e Seleção", duration: "1h" },
          { title: "Funções de Atualizar e Apagar", duration: "1h" },
          { title: "Funções de Agregação e Agrupamento", duration: "1h" },
          { title: "Constrains", duration: "1h" }
        ]
      },
      {
        title: "Projetos de Banco de Dados",
        moduleNumber: "4ª Módulo",
        topics: [{ title: "Projetos de Banco de Dados", duration: "2h" }]
      },
    ],
    instructor: {
      name: 'Sullivan Lima',
      description: 'Administrador, Consultor em Gestão de Processos e Analista de Sistemas',
      image: '/img/Me-About.jpeg',
      description_2: 'Sullivan Lima é formado em Administração e em Análise e Desenvolvimento de Sistemas; Especialista em Gestão de Negócios e Gestão de Projetos; e CEO e Fundador da S & LIMA Consultoria e Assessoria. Possui mais de 10 anos de experiência atuando na melhoria e no mapeamento de processos referentes às áreas de suprimentos, administrativa e financeira.',
      description_3: 'Seu foco é gestão de processos orientada a dados. Possui sólidos conhecimentos em Business Analytics referente a construção de modelos relacionais e geração de relatórios para tomada de decisão.'
    },
    sideBarContent: {
      price: 'R$ 300,00',
      instructorName: 'Sullivan Lima',
      duration: '20 horas',
      numberOfStudents: 'XX'
    }
  }
];

function BusinessIntelligence(){
	BundleMin()
  bootstrapMin()
  return (
<>
      <div className="container">
        <div className="row">
          <div className="offset-xl-2 col-xl-8 col-md-12 col-12 mt-6">
            <div>
              <h1 className="mb-0 display-4">
                {courses[0].title}<br />
              </h1>
            </div>
          </div>
        </div>
      </div>
      {/* //  <!-- program single tabs --> */}
      <div className="pt-lg-10 pb-lg-16 py-6 ">
        <div className="container">
          <div className="row">
            <div className="col-xl-8 col-lg-8 col-md-7 col-sm-12 col-12 mb-4">
              <div>
                <ul className="nav-pills-border nav nav-pills nav-justified mb-5 " id="pills-tab" role="tablist">
                  <li className="nav-item">
                    <a className="nav-link active fw-bold" id="pills-Overview-tab" data-bs-toggle="pill" href="#pills-Overview" role="tab" aria-controls="pills-Overview" aria-selected="true">Visão Geral</a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link fw-bold" id="pills-Curriculum-tab" data-bs-toggle="pill" href="#pills-Curriculum" role="tab" aria-controls="pills-Curriculum" aria-selected="false">Ementa</a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link fw-bold" id="pills-Coach-tab" data-bs-toggle="pill" href="#pills-Coach" role="tab" aria-controls="pills-Coach" aria-selected="false">Instrutor</a>
                  </li>
                </ul>
                <div className="tab-content" id="pills-tabContent">
                  <OverviewComponent serviceContent={courses[0].overviewContent} />
                  <Ementa modules={courses[0].ementaModules} />
                  <DadosInstrutor instructor={courses[0].instructor} />
                </div>
              </div>
            </div>
            {/* <!-- sidebar --> */}
            <SideBarComponent
              preco={courses[0].sideBarContent.price}
              nome_instrutor={courses[0].sideBarContent.instructorName}
              duracao={courses[0].sideBarContent.duration}
              n_inscritos={courses[0].sideBarContent.numberOfStudents}
            />
          </div>
        </div>
      </div>
    </>
  )
}


export default BusinessIntelligence;
