import React, { useState, useEffect } from 'react';
import MenuProfile from './menuProfile';
import HeaderStudent from '../courses/headerstudent';

function CustomerProfile() {
    // Estado para armazenar as informações do cliente
    const [customerInfo, setCustomerInfo] = useState({
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        address: '',
        state: '',
        city: '',
        zipcode: '',
        publicProfile: true,
        showEmail: true
    });
    const [profileImage, setProfileImage] = useState(null);

    const handleDeleteImage = () => {
        setProfileImage(null); // Limpa a imagem definindo-a como null
    };

    // Função para carregar as informações do cliente
    useEffect(() => {
        // Simulando a obtenção das informações do cliente do backend
        const fetchCustomerInfo = async () => {
            try {
                // Aqui você faria uma solicitação ao backend para obter as informações do cliente
                // Substitua este código pela lógica real de busca das informações do cliente
                const response = await fetch('URL_DO_BACKEND_PARA_OBTENCAO_DAS_INFORMACOES_DO_CLIENTE');
                const data = await response.json();

                // Atualiza o estado com as informações obtidas do backend
                setCustomerInfo(data);
            } catch (error) {
                console.error('Erro ao buscar as informações do cliente:', error);
            }
        };

        // Chama a função para carregar as informações do cliente ao montar o componente
        fetchCustomerInfo();
    }, []);

    // Função para lidar com a submissão do formulário
    const handleSubmit = async (event) => {
        event.preventDefault();
        try {
            // Aqui você faria uma solicitação ao backend para atualizar as informações do cliente
            // Substitua este código pela lógica real de atualização das informações do cliente
            await fetch('URL_DO_BACKEND_PARA_ATUALIZACAO_DAS_INFORMACOES_DO_CLIENTE', {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(customerInfo)
            });
            alert('Informações atualizadas com sucesso!');
        } catch (error) {
            console.error('Erro ao atualizar as informações do cliente:', error);
            alert('Erro ao atualizar as informações. Por favor, tente novamente mais tarde.');
        }
    };

    // Função para lidar com a alteração dos campos do formulário
    const handleChange = (event) => {
        const { name, value } = event.target;
        setCustomerInfo({ ...customerInfo, [name]: value });
    };

    const handleImageChange = (event) => {
        const file = event.target.files[0];
        setProfileImage(file);
    };

    return (
        <> <HeaderStudent/>
            <div className="bg-shape bg-dark">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-12 col-lg-12 col-md-12 col-12">
                            <div className="pt-lg-8 pb-lg-12 pt-5 pb-0 mb-16 ">
                                <h1 className="h2 text-white mb-0">Meu Perfil</h1>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="pb-12 mt-lg-n18 mt-n10">
                <div className="container">
                    <div className="row">
                        <MenuProfile />
                        {/* <!-- dashboard nav --> */}
                        <div className="col-lg-9 col-md-8 col-12">
                            <div className="card rounded-3 mb-4">
                                <div className="card-header bg-white p-4">
                                    <h3 className="mb-0 h4">Meu Perfil</h3>
                                </div>
                                <div className="card-body p-4">
                                <div className="d-lg-flex align-items-center justify-content-between">
                                <div className="d-flex align-items-center mb-4 mb-lg-0">
                                <img src={profileImage ? URL.createObjectURL(profileImage) : "/img/Me-About.jpeg"} id="img-uploaded" className="avatar-xl rounded-circle" alt="" />
                                <div className="ms-3">
                                                <h4 className="mb-0">Carregar Foto</h4>
                                                <p className="mb-0 font-14">
                                                    PNG, JPG ou JPEG (não maior que 5MB).
                                                </p>
                                                <label htmlFor="upload-photo" className="btn btn-primary btn-sm">Carregar Foto</label>
                                            <input type="file" id="upload-photo" accept="image/*" onChange={handleImageChange} style={{ display: 'none' }}/>
                                            </div>
                                            </div>
                                            <div>
                                            <a href="#" className="btn btn-light btn-sm" onClick={handleDeleteImage}> Apagar Foto </a>                                       </div></div>
                                            
                                        <hr className="my-5" />
                                    <form onSubmit={handleSubmit} className="row">
                                        <div className="col-12 col-md-12">
                                            <h4 className="mb-3">Informações Pessoais</h4>
                                        </div>
                                        <div className="mb-3 col-12 col-md-6">
                                            <label className="form-label" htmlFor="fname">Nome<span className="text-danger">*</span></label>
                                            <input type="text" id="fname" name="firstName" value={customerInfo.firstName} onChange={handleChange} className="form-control" placeholder="Nome" required />
                                        </div>
                                        <div className="mb-3 col-12 col-md-6">
                                            <label className="form-label" htmlFor="lname">Sobrenome<span className="text-danger">*</span></label>
                                            <input type="text" id="lname" name="lastName" value={customerInfo.lastName} onChange={handleChange} className="form-control" placeholder="Sobrenome" required />
                                        </div>
                                        <div className="mb-3 col-12 col-md-6">
                                            <label className="form-label" htmlFor="email">E-mail<span className="text-danger">*</span></label>
                                            <input type="email" id="email" name="email" value={customerInfo.email} onChange={handleChange} className="form-control" placeholder="Endereço de E-mail" required />
                                        </div>
                                        <div className="mb-3 col-12 col-md-6">
                                            <label className="form-label" htmlFor="phone">Telefone</label>
                                            <input type="text" id="phone" name="phone" value={customerInfo.phone} onChange={handleChange} className="form-control" placeholder="Telefone" required />
                                        </div>
                                        <div className="col-12 col-md-12">
                                            <h4 className="mb-3 mt-3">Informações do Logradouro</h4>
                                        </div>
                                        <div className="mb-3 col-12 col-md-12">
                                            <label className="form-label" htmlFor="address">Endereço</label>
                                            <input type="text" id="address" name="address" value={customerInfo.address} onChange={handleChange} className="form-control" placeholder="Endereço Completo, nº, Bairro" required />
                                        </div>
                                        <div className="mb-3 col-12 col-lg-4">
                                            <label className="form-label">Estado</label>
                                            <select className="form-select" name="state" value={customerInfo.state} onChange={handleChange}>
                                                <option value="">Selecione seu Estado</option>
                                                {/* Adicione opções para os estados aqui */}
                                            </select>
                                        </div>
                                        <div className="mb-3 col-12 col-lg-4">
                                            <label className="form-label">Cidade</label>
                                            <select className="form-select" name="city" value={customerInfo.city} onChange={handleChange}>
                                                <option value="">Selecione sua Cidade</option>
                                                {/* Adicione opções para as cidades aqui */}
                                            </select>
                                        </div>
                                        <div className="mb-3 col-12 col-lg-4">
                                            <label className="form-label" htmlFor="zipcode">CEP</label>
                                            <input type="text" id="zipcode" name="zipcode" value={customerInfo.zipcode} onChange={handleChange} className="form-control" placeholder="Exemplo: 00000-000" required />
                                        </div>
                                        <div className="col-12">
                                            <button type="submit" className="btn btn-primary">
                                                Salvar Dados
                                            </button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                            {/* <!-- card --> */}
                            <div className="card rounded-3 mb-4 ">
                                {/* <!-- card header --> */}
                                <div className="card-header bg-white p-4">
                                    <h3 className="mb-0 h4">Informações de Privacidade</h3>
                                </div>
                                {/* <!-- card body --> */}
                                <div className="card-body p-4">
                                    <div className="d-md-flex justify-content-between align-items-center border-bottom pb-3 mb-3">
                                        <div>
                                            <h4 className="mb-1 h5">Perfil Público</h4>
                                            <p className="mb-0 font-14">
                                                Tornar seu perfil público significa que outros membros poderão visualizar suas informações básicas.
                                            </p>
                                        </div>
                                        <div>
                                            <div className="form-check form-switch">
                                                <input type="checkbox" className="form-check-input" id="publicProfile" checked={customerInfo.publicProfile} onChange={() => setCustomerInfo({ ...customerInfo, publicProfile: !customerInfo.publicProfile })} />
                                                <label className="form-check-label" htmlFor="publicProfile"></label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="d-md-flex justify-content-between align-items-center ">
                                        <div>
                                            <h4 className="mb-1 h5">Mostre seu E-mail</h4>
                                            <p className="mb-0 font-14">
                                                Essa informação permitirá que usuários lhe encontrem, por meio do seu e-mail.
                                            </p>
                                        </div>
                                        <div>
                                            <div className="form-check form-switch">
                                                <input type="checkbox" className="form-check-input" id="privacySetting" checked={customerInfo.showEmail} onChange={() => setCustomerInfo({ ...customerInfo, showEmail: !customerInfo.showEmail })} />
                                                <label className="form-check-label" htmlFor="privacySetting"></label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* <!-- card  --> */}
                            <div className="card rounded-3 ">
                                <div className="card-body p-4">
                                    <p className="mb-0">
                                        <i className="fe fe-trash-2 font-20  me-2 text-primary"></i>Se você não deseja mais ser nosso cliente, você pode excluir seu perfil:
                                        <a href="#">Excluir Conta</a>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default CustomerProfile;
