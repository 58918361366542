import React from 'react';


function NossosCursos(){

  return (<>        

  <div class="container">
   <div class="row">
        <nav aria-label="Page navigation example">
            <ul class="pagination justify-content-center mt-4 mb-4">
                <li class="page-item disabled">
                    <a class="page-link" href="#" tabindex="-1" aria-disabled="true">Anterior</a>
                </li>
                <li class=" page-item disabled"><a class="page-link" href="course-thumbnail.html" id="page-1">1</a>
                </li>
                <li class="page-item disabled">
                    <a class="page-link" href="#" id="next-page">Próxima</a>
                </li>
            </ul>
        </nav>
        </div>
        </div>
  </>)
}

export default NossosCursos;