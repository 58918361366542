import React from 'react';

function CourseItem({ imageSrc, badge, title, preco_de, rating, duration, lectures, link, preco_por}) {
  return (
    <>
    <div className="col-sm-6 col-lg-4 col-xl-3">
<figure class="caption-hover">
      <div className="card shadow h-100">
        {/* Image */}<a href={link} class="text-white">
        <img src={imageSrc} className="card-img-top" alt="course image"/>
        </a>
        {/* Card body */}
        <div className="card-body pb-0">
          {/* Badge and favorite */}
          <div className="d-flex justify-content-between mb-2">
            <a href={link} className="badge  text-muted">{badge}</a>
            <a href={link} className="h6 mb-0"><i className="far fa-heart"></i></a>
          </div>
          {/* Title */}
          <h5 className="card-title fw-normal"><a href={link}>{title}</a></h5>
          <span class="fw-bold text-danger h6 mb-0"><strike>{preco_de}</strike></span>
          <br/>
          <span class="fw-bold text-sucess h5 mb-0">{preco_por}</span>
          {/* Rating star */}
          <ul className="list-inline mb-0">
            {rating && (
              <>
                {[...Array(rating)].map((_, index) => (
                  <li key={index} className="list-inline-item me-0 small"><i className="fas fa-star text-warning"></i></li>
                ))}
                {[...Array(5 - rating)].map((_, index) => (
                  <li key={index} className="list-inline-item me-0 small"><i className="far fa-star text-warning"></i></li>
                ))}
              </>
            )}
            <li className="list-inline-item ms-2 h6 fw-light mb-0">{rating}/5.0</li>
          </ul>
          
        </div>
        {/* Card footer */}
            <figcaption class="text-center mt-2"><a href={link} class="text-white">Saiba Mais...</a></figcaption>
        <div className="card-footer pt-0 pb-3">
          <hr/>
          <div className="d-flex justify-content-between">
            <span className="h6 fw-light mb-0"><i className="far fa-clock text-danger me-2"></i>{duration}</span>
            <span className="h6 fw-light mb-0"><i className="fas fa-table text-orange me-2"></i>{lectures} Projetos</span>
          </div>
        </div>
      </div>
            </figure>
    </div>
    </>
  )
}

export default CourseItem;