import React, { useState } from 'react';
// import { Accordion, Card } from 'react-bootstrap';
import {BundleMin} from '../../utils/bootstrap.bundle.min';




function ItemClassrom({video, title, description, customClass}){

	BundleMin()
  return(
    <>
                <div class={`card-body border-top p-4 ${customClass}`}>
                  <div class="row">
                    <div class="col-md-9">
                      <div class="d-flex align-items-center">
                        {/* <button class="fas fa-play icon-shape rounded-circle btn-controles icon-md text-center"></button> */}
                        <video src={video} style={{ width: '150px', padding: '10px' }} onclick="changeVideo('/videos/teste_1.mp4')"></video>
                        <div>
                          <h5>{title}</h5>
                          <p class="mb-0">
                            {description}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
    </>
  )
}
export default ItemClassrom; 	