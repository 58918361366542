import React from 'react'
import MenuProfile from './menuProfile';

function Assinatura(){
  return(
    // <!-- pageheader section -->
    <>
    <div class="bg-shape bg-dark">
        <div class="container">
            <div class="row">
                <div class="col-xl-12 col-lg-12 col-md-12 col-12">
                    <div class="pt-lg-8 pb-lg-12 pt-5 pb-0 mb-16 ">
                        <h1 class="h2 text-white mb-0">Assinaturas Contratadas</h1>
                    </div>
                </div>
            </div>
        </div>
    </div>
    {/* // <!-- dashboard nav --> */}
    <div class="pb-12 mt-lg-n18 mt-n10">
        <div class="container">
            <div class="row">
                <MenuProfile/>
                {/* <!-- dashboard subscriptions --> */}
                <div class="col-lg-9 col-md-8 col-12">
                    <div class="card rounded-3 mb-4 ">
                        {/* <!-- card header --> */}
                        <div class="card-header bg-white p-4">
                            <h3 class="mb-0 h4">Planos de Assinatura</h3>
                        </div>
                        {/* <!-- card body --> */}
                        <div class="card-body p-4">
                            <div class="bg-light-primary p-4 rounded-3 mb-4">
                                <div class="d-flex justify-content-between mb-4 ">
                                    <div class="font-13">
                                        <h4 class="mb-1">Plano Pago</h4>
                                        <span>Acesso a todos os cursos, podcasts, palestras e E-books.</span>
                                    </div>
                                    <div>
                                        <span class="h4">R$ Xx,xx</span><span class="font-13 text-muted ms-1">/ month</span>
                                    </div>
                                </div>
                                <div class="d-flex justify-content-between">
                                    <div>
                                        <a href="#" class="btn btn-primary btn-sm me-4">Upgrade do Meu Plano</a><a href="#" class="text-danger">Cancelar Meu Plano Pago</a>
                                    </div>
                                    <div class="font-13">
                                        <span>Próximo Vencimento</span><span class="text-dark ms-2 fw-bold">XX de Janeiro de 2023</span>
                                    </div>
                                </div>
                            </div>
                            <div class="bg-light p-4 rounded-3">
                                <div class="d-flex justify-content-between mb-4">
                                    <div class="font-13">
                                        <h4 class="mb-1">Plano Gratuito</h4>
                                        <span>Acesso a um mini curso e a um E-book. Podcasts e Palestras (Limitados)</span>
                                    </div>
                                    <div>
                                        <span class="h4">Gratuito</span><span class="font-13 text-muted ms-1">/ month</span>
                                    </div>
                                </div>
                                <div class="d-flex justify-content-between">
                                    <div>
                                        <a href="#" class="btn btn-dark btn-sm me-4 disabled">Cancelar</a>
                                    </div>
                                    <div class="font-13">
                                        <span>Próximo Vencimento</span><span class="text-dark ms-2 fw-bold">Sem Vencimentos</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <!-- card  --> */}
                    <div class="card mb-4 ">
                        {/* <!-- card header --> */}
                        <div class="card-header bg-white p-4 d-flex justify-content-between align-items-center">
                            <h4 class="mb-0">Métodos de Pagamento</h4>
                            <a href="#" class="btn btn-primary btn-sm">Adicionar Novo</a>
                        </div>
                        {/* <!-- card body --> */}
                        <div class="card-body p-4 ">
                            <ul class="list-group list-group-flush">
                                <li class="list-group-item px-0 pb-3 pt-0">
                                    <div class="d-flex justify-content-between">
                                        <div class="d-flex">
                                            <img src="../assets/images/logo-visa.svg" alt="" class="me-3" />
                                            <div>
                                                <h5 class="mb-0">Visa Final 1234</h5>
                                                <p class="mb-0 font-13">Vence em 10/2021</p>
                                            </div>
                                        </div>
                                        <div>
                                            <span class="badge bg-success me-lg-4 me-0 ">Método Principal</span>
                                            <span class="dropdown">
                                                <a class="text-muted text-decoration-none" href="#" role="button" id="dropdownOne" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                    <i class="fe fe-more-vertical"></i>
                                                </a>

                                                <span class="dropdown-menu dropdown-menu-right dropdown-menu-md-left" aria-labelledby="dropdownOne">
                                                    <a class="dropdown-item" href="#">Editar</a>
                                                    <a class="dropdown-item" href="#">Remover</a>
                                                    <a class="dropdown-item" href="#">Transformar Método Principal</a>
                                                </span>
                                            </span>
                                        </div>
                                    </div>
                                </li>
                                <li class="list-group-item px-0 py-3">
                                    <div class="d-flex justify-content-between">
                                        <div class="d-flex">
                                            <img src="../assets/images/logo-mastercard.svg" alt="" class="me-3" />
                                            <div>
                                                <h5 class="mb-0">Mastercard Final 1234</h5>
                                                <p class="mb-0 font-13">Vence em 03/2022</p>
                                            </div>
                                        </div>

                                        <span class="dropdown">
                                            <a class="text-muted text-decoration-none" href="#" role="button" id="dropdownTwo" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                <i class="fe fe-more-vertical"></i>
                                            </a>

                                            <span class="dropdown-menu dropdown-menu-right dropdown-menu-md-left" aria-labelledby="dropdownTwo">
                                                <a class="dropdown-item" href="#">Editar</a>
                                                <a class="dropdown-item" href="#">Remover</a>
                                                <a class="dropdown-item" href="#">Transformar Método Principal</a>
                                            </span>
                                        </span>
                                    </div>
                                </li>
                                <li class="list-group-item px-0 py-3">
                                    <div class="d-flex justify-content-between">
                                        <div class="d-flex">
                                            <img src="../assets/images/logo-discover.svg" alt="" class="me-3" />
                                            <div>
                                                <h5 class="mb-0">Discover Final 1234</h5>
                                                <p class="mb-0 font-13">Vence em 07/2021</p>
                                            </div>
                                        </div>

                                        <span class="dropdown">
                                            <a class="text-muted text-decoration-none" href="#" role="button" id="dropdownThree" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                <i class="fe fe-more-vertical"></i>
                                            </a>

                                            <span class="dropdown-menu dropdown-menu-right dropdown-menu-md-left" aria-labelledby="dropdownThree">
                                                <a class="dropdown-item" href="#">Editar</a>
                                                <a class="dropdown-item" href="#">Remover</a>
                                                <a class="dropdown-item" href="#">Transformar Método Principal</a>
                                            </span>
                                        </span>
                                    </div>
                                </li>
                                <li class="list-group-item px-0 py-3">
                                    <div class="d-flex justify-content-between">
                                        <div class="d-flex">
                                            <img src="../assets/images/logo-americanexpress.svg" alt="" class="me-3" />
                                            <div>
                                                <h5 class="mb-0">American Express Final 1234</h5>
                                                <p class="mb-0 font-13">Vence em 12/2021</p>
                                            </div>
                                        </div>

                                        <span class="dropdown">
                                            <a class="text-muted text-decoration-none" href="#" role="button" id="dropdownFour" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                <i class="fe fe-more-vertical"></i>
                                            </a>

                                            <span class="dropdown-menu dropdown-menu-right dropdown-menu-md-left" aria-labelledby="dropdownFour">
                                                <a class="dropdown-item" href="#">Editar</a>
                                                <a class="dropdown-item" href="#">Remover</a>
                                                <a class="dropdown-item" href="#">Transformar Método Principal</a>
                                            </span>
                                        </span>
                                    </div>
                                </li>
                                <li class="list-group-item px-0 pt-3 pb-0">
                                    <div class="d-flex justify-content-between">
                                        <div class="d-flex">
                                            <img src="../assets/images/logo-paypal-second.svg" alt="" class="me-3" />
                                            <div>
                                                <h5 class="mb-0">Paypal Express Final 1234</h5>
                                                <p class="mb-0 font-13">Vence em 10/2021</p>
                                            </div>
                                        </div>

                                        <span class="dropdown">
                                            <a class="text-muted text-decoration-none" href="#" role="button" id="dropdownFive" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                <i class="fe fe-more-vertical"></i>
                                            </a>

                                            <span class="dropdown-menu dropdown-menu-right dropdown-menu-md-left" aria-labelledby="dropdownFive">
                                                <a class="dropdown-item" href="#">Editar</a>
                                                <a class="dropdown-item" href="#">Remover</a>
                                                <a class="dropdown-item" href="#">Transformar Método Principal</a>
                                            </span>
                                        </span>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                    {/* <!-- card --> */}
                    <div class="card ">
                        {/* <!-- card header --> */}
                        <div class="card-header bg-white p-4">
                            <h4 class="mb-0">Histórico de Pagamento</h4>
                        </div>
                        {/* <!-- card body --> */}
                        <div class="card-body p-4 ">
                            {/* <!-- list --> */}
                            <ul class="list-group list-group-flush">
                                <li class="list-group-item px-0 pb-3 pt-0">
                                    <div class="d-flex justify-content-between">
                                        <div>
                                            <a href="#">Invoice #00001</a>
                                            <p class="mb-0 font-13">Faturar em 21 de Fevereiro de 2023</p>
                                        </div>

                                        <div>
                                            <a href="#" class="btn btn-light btn-sm">Realizar Pagamento</a>
                                        </div>
                                    </div>
                                </li>
                                {/* <!-- list --> */}
                                <li class="list-group-item px-0 py-3">
                                    <div class="d-flex justify-content-between">
                                        <div>
                                            <a href="#">Invoice #00002</a>
                                            <p class="mb-0 font-13">Faturado em 21 de Janeiro de 2023</p>
                                        </div>

                                        <div>
                                            <a href="#" class="btn btn-light-success btn-sm text-success">Pago</a>
                                        </div>
                                    </div>
                                </li>
                                {/* <!-- list --> */}
                                <li class="list-group-item px-0 py-3">
                                    <div class="d-flex justify-content-between">
                                        <div>
                                            <a href="#">Invoice #00003</a>
                                            <p class="mb-0 font-13">Faturado em 21 de Janeiro de 2023</p>
                                        </div>

                                        <div>
                                            <a href="#" class="btn btn-light-success btn-sm text-success">Pago</a>
                                        </div>
                                    </div>
                                </li>
                                {/* <!-- list --> */}
                                <li class="list-group-item px-0 py-3">
                                    <div class="d-flex justify-content-between">
                                        <div>
                                            <a href="#">Invoice #00004</a>
                                            <p class="mb-0 font-13">Faturado em 21 de Janeiro de 2023</p>
                                        </div>

                                        <div>
                                            <a href="#" class="btn btn-light-success btn-sm text-success">Pago</a>
                                        </div>
                                    </div>
                                </li>
                                {/* <!-- list --> */}
                                <li class="list-group-item px-0 pt-3 pb-0">
                                    <div class="d-flex justify-content-between">
                                        <div>
                                            <a href="#">Invoice #00005</a>
                                            <p class="mb-0 font-13">Faturado em 21 de Janeiro de 2023</p>
                                        </div>

                                        <div>
                                            <a href="#" class="btn btn-light-success btn-sm text-success">Pago</a>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div></>
  )
}

export default Assinatura;