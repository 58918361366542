// SideBarComponent.jsx
function SideBarComponent({nome_instrutor, preco, duracao, n_inscritos}) {
  return (
    <div className="col-xl-4 col-lg-4 col-md-5 col-sm-12 col-12 mb-8">
      <div className="card">
        <ul className="list-group list-group-flush">
          <li className="list-group-item py-3">
            <div className="d-flex justify-content-between align-items-center">
              <div>
                <div className="d-flex align-items-center">
                  <i className="fas fa-money-bill-alt me-2 font-16 fa-fw text-muted"></i>
                  <h5 className="mb-0 fw-medium">Preço deste serviço</h5>
                </div>
              </div>
              <div>
                <h5 className="mb-0 text-primary fw-bold">{preco}</h5>
              </div>
            </div>
          </li>
          <li className="list-group-item py-3">
            <div className="d-flex justify-content-between align-items-center">
              <div>
                <div className="d-flex align-items-center">
                  <i className="fas fa-user-tie me-2 font-16 fa-fw text-muted"></i>
                  <h5 className="mb-0 fw-medium">Instrutor</h5>
                </div>
              </div>
              <div>
                <h5 className="mb-0 text-dark">{nome_instrutor}</h5>
              </div>
            </div>
          </li>
          <li className="list-group-item py-3">
            <div className="d-flex justify-content-between align-items-center">
              <div>
                <div className="d-flex align-items-center">
                  <i className="fas fa-clock me-2 font-16 fa-fw text-muted"></i>
                  <h5 className="mb-0 fw-medium">Duração</h5>
                </div>
              </div>
              <div>
                <p className="mb-0 text-muted">{duracao}</p>
              </div>
            </div>
          </li>
          <li className="list-group-item py-3">
            <div className="d-flex justify-content-between align-items-center">
              <div>
                <div className="d-flex align-items-center">
                  <i className="fas fa-user me-2 font-16 fa-fw text-muted"></i>
                  <h5 className="mb-0 fw-medium">Alunos</h5>
                </div>
              </div>
              <div>
                <p className="mb-0 text-muted">{n_inscritos}</p>
              </div>
            </div>
          </li>
          <li className="list-group-item py-3">
            <div className="d-grid">
              <a href="/login" className="btn btn-primary">Inscreva-se neste curso</a>
            </div>
          </li>
        </ul>
      </div>
    </div>
  );
}

export default SideBarComponent;
