import React from 'react';
import { detectOverflow } from '@popperjs/core'
import {bootstrapMin} from '../../utils/bootstrap.min'

import ItemClassrom from './itemclassrom';

function DisciplinasProgramacao(){
  bootstrapMin()
  return(
    <>
            <div class="mb-8">
          <h3 class="mb-4 text-white">Programação das Disciplinas</h3>
          <ul class="nav-pills-border nav nav-pills nav-justified mb-5" id="pills-tab" role="tablist">
            <li class="nav-item">
              <a class="nav-link active fw-bold" id="pills-eventOne-tab" data-bs-toggle="pill" href="#pills-eventOne" role="tab" aria-controls="pills-eventOne" aria-selected="true">1º Módulo</a>
            </li>
            <li class="nav-item">
              <a class="nav-link fw-bold" id="pills-eventTwo-tab" data-bs-toggle="pill" href="#pills-eventTwo" role="tab" aria-controls="pills-eventTwo" aria-selected="false">2º Módulo</a>
            </li>
            <li class="nav-item">
              <a class="nav-link fw-bold" id="pills-eventThree-tab" data-bs-toggle="pill" href="#pills-eventThree" role="tab" aria-controls="pills-eventThree" aria-selected="false">3º Módulo</a>
            </li>
            <li class="nav-item">
              <a class="nav-link fw-bold" id="pills-eventFour-tab" data-bs-toggle="pill" href="#pills-eventFour" role="tab" aria-controls="pills-eventFour" aria-selected="false">4º Módulo</a>
            </li>
          </ul>
          <div class="tab-content" id="pills-tabContent">
            <div class="tab-pane fade show active" id="pills-eventOne" role="tabpanel" aria-labelledby="pills-eventOne-tab">
              <div class="card">
                <div class="card-header">
                  <h4 class="h5 mb-0">Conceitos Iniciais</h4>
                </div>
<ItemClassrom 
title='1. Conhecendo o Microsoft Power BI'
description='Nesta aula conheceremos sobre os princípios que envolvem segurança da informação.'
video='/videos/teste_1.mp4'
/>

<ItemClassrom 
title='2. Instalando e Configurando o Microsoft Power BI'
description='Nesta aula conheceremos sobre os princípios que envolvem segurança da informação.'
video='/videos/teste_1.mp4'
customClass='bg-light'
/>

<ItemClassrom 
title='3. Iniciando os Primeiros Passos'
description='Nesta aula conheceremos sobre os princípios que envolvem segurança da informação.'
video='/videos/teste_1.mp4'
customClass=''
/>

<ItemClassrom 
title='4. Realizandos os Primeiros Tratamentos de Dados'
description='Nesta aula conheceremos sobre os princípios que envolvem segurança da informação.'
video='/videos/teste_1.mp4'
customClass='bg-light'
/>

              </div>
            </div>
            <div class="tab-pane fade" id="pills-eventTwo" role="tabpanel" aria-labelledby="pills-eventTwo-tab">
              <div class="card">
                <div class="card-header">
                  <h4 class="h5 mb-0">Trabalhando com Editor Power Query</h4>
                </div>
                <ItemClassrom 
title='5. Noções Introdutórias'
description='Nesta aula conheceremos sobre os princípios que envolvem segurança da informação.'
video='/videos/teste_1.mp4'
/>

<ItemClassrom 
title='6. Transformação de Dados'
description='Nesta aula conheceremos sobre os princípios que envolvem segurança da informação.'
video='/videos/teste_1.mp4'
customClass='bg-light'
/>

<ItemClassrom 
title='7. Trabalhando com Colunas'
description='Nesta aula conheceremos sobre os princípios que envolvem segurança da informação.'
video='/videos/teste_1.mp4'
customClass=''
/>

<ItemClassrom 
title='8. Ferramentas e Ajuda'
description='Nesta aula conheceremos sobre os princípios que envolvem segurança da informação.'
video='/videos/teste_1.mp4'
customClass='bg-light'
/>
              </div>
            </div>
            <div class="tab-pane fade" id="pills-eventThree" role="tabpanel" aria-labelledby="pills-eventThree-tab">
              <div class="card">
                <div class="card-header">
                  <h4 class="h5 mb-0">Relacionamentos de Dados Entre Tabelas</h4>
                </div>
                <ItemClassrom 
                title='9. Conceitos de Modelagem de Dados'
                description='Nesta aula conheceremos sobre os princípios que envolvem segurança da informação.'
                video='/videos/teste_1.mp4'
                />

                <ItemClassrom 
                title='10. Criando Suas Relações e Modelo de Dados'
                description='Nesta aula conheceremos sobre os princípios que envolvem segurança da informação.'
                video='/videos/teste_1.mp4'
                customClass='bg-light'
                />

                <ItemClassrom 
                title='11. Início de Algumas Funções DAX (Data Analysis Expressions)'
                description='Nesta aula conheceremos sobre os princípios que envolvem segurança da informação.'
                video='/videos/teste_1.mp4'
                customClass=''
                />

                <ItemClassrom 
                title='12. Criando Seus Primeiros Painéis - Dashboards'
                description='Nesta aula conheceremos sobre os princípios que envolvem segurança da informação.'
                video='/videos/teste_1.mp4'
                customClass='bg-light'
                />
              </div>
            </div>
            <div class="tab-pane fade" id="pills-eventFour" role="tabpanel" aria-labelledby="pills-eventFour-tab">
              <div class="card">
                <div class="card-header">
                  <h4 class="h5 mb-0">Projetos de Power BI</h4>
                </div>
                <ItemClassrom 
                title='13. Projetos de Power BI'
                description='Nesta aula conheceremos sobre os princípios que envolvem segurança da informação.'
                video='/videos/teste_1.mp4'
                />
              </div>
            </div>
          </div>
        </div>
    </>
  )
}

export default DisciplinasProgramacao;