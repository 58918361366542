import React from 'react';

function Filters(){
  return (
  <>
  <div class="container">
      <div class="pt-lg-16 pb-lg-4 py-6 ">
        <div class="container">
            <div class="row">
                <div class="col-lg-12 col-md-12 col-12">
                    <div class="d-md-flex justify-content-between mb-0">
                        <div>
                            <h4>Nossos Cursos Disponíveis</h4>
                        </div>
                        <div>
                            <div class="d-inline-block me-2">
                                <span>Ordenar Por:</span>
                            </div>
                            <div class="d-inline-block">
                                <select class="form-select">
                                    <option selected>Filtrar</option>
                                    <option value="1">Mais Populares</option>
                                    <option value="2">Menor Valor</option>
                                    <option value="3">Maior Valor</option>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            </div>
            </div>
            </div>
  </>

  )
}

export default Filters;