import React, { useEffect } from 'react';
import HeaderStudent from './headerstudent';
import PersonClassrom from './personclassrom';
import QuestionsClassrom from './questionsclassrom';
import DisciplinasProgramacao from './disciplinasprogramacao';
import Footer from '../footer';
import { useNavigate } from 'react-router-dom';

function Class({titulo, description}) {
  const navigate = useNavigate();

  const handleLogout = () => {
    navigate('/');
  };

  useEffect(() => {
    const videoPlayer = document.getElementById("videoPlayer");
    const videoCover = document.getElementById("videoCover");
    const controlesVideo = document.getElementById("controlesVideo");

    if (videoPlayer) {
      const progressBar = document.getElementById("progressBar");
      const btnPlay = document.getElementById('btn-play');
      const btnStop = document.getElementById("btn-stop");
      const btnPause = document.getElementById("btn-pause");
      const btnForward = document.getElementById("btn-forward");
      const btnBackward = document.getElementById("btn-backward");
      const btnFullscreen = document.getElementById("btn-fullscreen");
      const volumeBar = document.getElementById("btn-volume");

      btnPlay.addEventListener("click", function () {
        videoPlayer.play();
      });

      btnStop.addEventListener("click", function () {
        videoPlayer.pause();
        videoPlayer.currentTime = 0;
      });

      btnPause.addEventListener("click", function () {
        videoPlayer.pause();
      });

      btnForward.addEventListener("click", function () {
        videoPlayer.currentTime += 10;
      });

      btnBackward.addEventListener("click", function () {
        videoPlayer.currentTime -= 10;
      });

      videoPlayer.addEventListener("timeupdate", function () {
        var progress = (videoPlayer.currentTime / videoPlayer.duration) * 100;
        progressBar.value = progress;
      });

      progressBar.addEventListener("input", function () {
        var time = (progressBar.value / 100) * videoPlayer.duration;
        videoPlayer.currentTime = time;
      });

      btnFullscreen.addEventListener("click", function () {
        if (videoPlayer.requestFullscreen) {
          videoPlayer.requestFullscreen();
        } else if (videoPlayer.mozRequestFullScreen) {
          videoPlayer.mozRequestFullScreen();
        } else if (videoPlayer.webkitRequestFullscreen) {
          videoPlayer.webkitRequestFullscreen();
        } else if (videoPlayer.msRequestFullscreen) {
          videoPlayer.msRequestFullscreen();
        }
      });

      volumeBar.addEventListener("input", function () {
        videoPlayer.volume = volumeBar.value;
      });

      videoPlayer.addEventListener('play', function() {
        videoCover.style.visibility = 'hidden';
      });

      videoPlayer.addEventListener('pause', function() {
        videoCover.style.visibility = 'visible';
      });

      videoPlayer.addEventListener('ended', function() {
        videoCover.style.visibility = 'visible';
      });
    }
  }, []);

  titulo = "Curso de Power BI";
  description="Descrição breve do curso de power BI";

  return (
    <>
      <HeaderStudent />
      <div class="py-lg-16 py-12 bg-dark-gradient-c">
  <div class="container">
      <PersonClassrom/>
    <div class="row">
      {/* <!-- Coluna principal para o vídeo --> */}
      <div class="col-lg-8 col-md-8 col-12">
        <div class="mb-8 mt-4 position-relative">
          <h3 class="mb-4 text-white">{titulo}</h3>
          <p class="text-muted">{description}</p>
          {/* <!-- Vídeo --> */}
          <div id="videoContainer" class="position-relative">
          <video id="videoPlayer" class='v-content' poster="">
            <source src="../../videos/teste_1.mp4" type="video/mp4"/>
            Desculpe, mas o seu navegador não suporta o formato de vídeo.
          </video>
          <img src="../../img/Frame.svg" alt="Capa do vídeo" class="v-content video-cover" id="videoCover" />
          <div id="controlesVideo" class="position-absolute bottom-0 start-0 w-100 d-flex justify-content-center align-items-center">
          <div class="d-flex justify-content-between align-items-center w-100">
              <input type="range" id="progressBar" min="0" value="0"/>

            <i class="fas fa-play icon-shape rounded-circle btn-controles icon-md" id="btn-play"></i>
            <i class="fas fa-pause icon-shape rounded-circle btn-controles icon-md" id="btn-pause"></i>
            <i class="fas fa-stop icon-shape rounded-circle btn-controles icon-md" id="btn-stop"></i>
            <i class="fas fa-forward icon-shape rounded-circle btn-controles icon-md" id="btn-forward"></i>
            <i class="fas fa-backward icon-shape rounded-circle btn-controles icon-md" id="btn-backward"></i>
            <i class="fas fa-expand icon-shape rounded-circle btn-controles icon-md" id="btn-fullscreen"></i>
            <i class="fas fa-volume-up icon-shape rounded-circle btn-controles icon-md" id="btn-volume"></i>
          </div>
          </div>
        </div>
        </div>
      </div>
      {/* <!-- Coluna lateral para informações adicionais --> */}
      <div class="col-lg-4 col-md-4 col-12">
        <div class="mb-4 mt-5">
          <h3 class="mb-4 text-white">Informações Adicionais</h3>
        </div>
        {/* <!-- Informações sobre o professor --> */}
        <div class="card mb-2">
          <div class="card-body p-4">
            <div class="mb-4">
              <i class="fas fa-user-tie icon-shape icon-lg bg-light-secondary rounded-circle text-secondary"></i>
            </div>
            <h4 class="h5">Professor, Sullivan Lima</h4>
            <p class="mb-0">Administrador, Desenvolvedor de Sistemas e Certificado Green Belt Lean Six Sigma. Pós graduado em Gestão Empresarial e de Projetos.</p>
          </div>
        </div>
        {/* <!-- Informação importante sobre o curso --> */}
        <div class="card mb-2">
          <div class="card-body p-4">
            <h4 class="h5">Importante!</h4>
            <p class="mb-0">Neste curso você contará com um ao final de cada cada módulo estudado, bem como contará com a opção de enviar perguntas específicas ao tutor deste curso</p>
          </div>
        </div>
      </div>
      {/* <!-- Componente de disciplinas de programação --> */}
      <DisciplinasProgramacao/>
    </div>
    {/* <!-- Componente de perguntas da sala de aula --> */}
    <QuestionsClassrom />
  </div>
</div>

  <Footer/>
  </>
  )
};

export default Class;