import React from 'react';


function Footer(){
  return(
    <footer class="footer pt-11 pb-3 bg-dark text-body">
  <div class="container">
    <div class="row">
      <div class="col-lg-2 col-md-4 col-6">
        <div class="mb-4">
          <h4 class="mb-4 text-white">Sobre</h4>
          <ul class="list-unstyled list-group">
            <li class="list-group-item"><a href="/team" class="list-group-item-link">Nossos Profissionais</a></li>
            <li class="list-group-item"><a href="#contato" class="list-group-item-link">Contate-nos</a></li>
            <li class="list-group-item"><a href="#ajuda" class="list-group-item-link">Ajuda</a></li>
          </ul>
        </div>
      </div>
      <div class="col-lg-3 col-md-4 col-6">
        <div class="mb-4">
          <h4 class="mb-4 text-white ">Nossos Serviços</h4>
          <ul class="list-unstyled list-group ">
            <li class="list-group-item"><a href="/bi-services" class="list-group-item-link">Business Intelligence</a></li>
            <li class="list-group-item"><a href="/portifolio" class="list-group-item-link">Desenvolvimento Web</a></li>
            <li class="list-group-item"><a href="/process" class="list-group-item-link">Processos</a></li>
            <li class="list-group-item"><a href="/planejamento" class="list-group-item-link">Planejamento</a></li>
          </ul>
        </div>
      </div>
      <div class="col-lg-2 col-md-4 col-4">
        <div class="mb-4">
          <h4 class="mb-4 text-white ">Sigam-nos !</h4>
          <ul class="list-unstyled list-group">

            <li class="list-group-item"><a href="https://www.instagram.com/slima_consult/" class="list-group-item-link" target='_blank'>
                <i class="fab fa-instagram"></i> Instagram</a></li>
            {/* <li class="list-group-item"><a href="#" class="list-group-item-link"> 
                <i class="fab fa-facebook"></i> Facebook</a></li> */}
            <li class="list-group-item"><a href="https://www.linkedin.com/in/sullivanlima/" class="list-group-item-link" target='_blank'>
                <i class="fab fa-linkedin"></i> LinkedIn</a></li>
            <li class="list-group-item"><a href="https://www.youtube.com/@sullivanlima8154" class="list-group-item-link" target='_blank'>
                <i class="fab fa-youtube"></i> YouTube</a></li>
            <li class="list-group-item"><a href="#" class="list-group-item-link">
                <i class="fab fa-twitter"></i> Twitter</a></li>
          </ul>
        </div>
      </div>
      <div class="col-lg-2 col-md-4 col-6">
      </div>
      {/* <div class="col-lg-4 col-md-8 col-6">
        <div class="mb-4">
          <h4 class="mb-4 text-white">Assine nossa newsletter</h4>
          <div>
            <p>Inscreva-se em nossa newsletter e seja notificado diariamente sobre nossas dicas.</p>
            <form>
              <div class="mb-3">
                <input type="email" class="form-control border-white" placeholder="Insira seu E-mail" required/>
              </div>
              <button type="submit" class="btn btn-primary">Assinar</button>
            </form>
          </div>
        </div>
      </div> */}
      <div class="col-md-12">
        <div class="mt-8">
          <ul class="list-inline">
            <li class="list-inline-item">© 2024 S & Lima Consultoria e Assessoria.</li>
            <li class="list-inline-item"><a href="#" class="text-reset"> Política de Privacidade</a></li>
            <li class="list-inline-item"><a href="#" class="text-reset"> Termos e Condições</a></li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</footer>
  )
}

export default Footer;