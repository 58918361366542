import React from 'react'
import { detectOverflow } from '@popperjs/core'
import {bootstrapMin} from '../../utils/bootstrap.min'
import SideBarComponent from './sidebarcourse'
import DadosInstrutor from './dadosInstrutor'
import Ementa from './ementa'
import OverviewComponent from './overviewcourse'
import {BundleMin} from '../../utils/bootstrap.bundle.min';

const courses = [
  {
    title: "Banco de Dados",
    overviewContent: {
      intro: "Neste curso trabalharemos com você conceitos sobre banco de dados, bem como trabalharemos a linguagem SQL usadas em banco de dados relacionais.",
      paragraphs: [
        "Neste curso, exploramos conjuntos de dados reais e exemplos relacionados a diversas áreas de negócios, como Finanças, Recursos Humanos, Compras e Logística, entre outras. Nosso objetivo é promover uma experiência de aprendizagem participativa e motivadora, capacitando você a desenvolver habilidades essenciais para alcançar seus objetivos no domínio dos bancos de dados relacionais e da linguagem SQL.",

        "Assim, ao longo deste curso, os alunos não apenas terão a oportunidade de explorar conjuntos de dados reais e exemplos aplicados a diversas áreas de negócios, mas também desenvolverão habilidades essenciais no domínio dos bancos de dados relacionais e da linguagem SQL. Com uma abordagem participativa e motivadora, nosso objetivo é capacitar os alunos a compreenderem e aplicarem conceitos fundamentais de bancos de dados relacionais, bem como dominarem as técnicas de manipulação e consulta de dados usando SQL. Ao final do curso, os alunos estarão aptos a realizar análises complexas, construir consultas eficientes e extrair informações valiosas de bancos de dados relacionais para atender às demandas das organizações modernas."
      ],
      services: [
        "Conceitos sobre Dados",
        "Criar Banco de Dados",
        "Criar Relacionamentos",
        "Linguagem SQL",
        "e mais..."
      ],
      methodology: "Nossa metodologia é cuidadosamente projetada para oferecer uma experiência de aprendizado prático e alinhada com as demandas do mercado de trabalho. Através de uma combinação de exposição a situações reais, exercícios práticos e correções personalizadas, os alunos têm a oportunidade de absorver o conteúdo de forma eficaz. Abordamos questões teóricas não como uma forma de avaliação, mas sim como ferramentas auxiliares para a compreensão e consolidação do conhecimento. Acreditamos que essa abordagem proporciona um ambiente de aprendizado dinâmico e estimulante, preparando os alunos para enfrentar os desafios do mundo profissional com confiança e habilidade."
    },
    ementaModules: [
      {
        title: "Conceitos Introdutórios",
        moduleNumber: "1ª Módulo",
        topics: [
          { title: "Introdução a Banco de Dados", duration: "1h" },
          { title: "Conceito de SGBDs", duration: "1h" },
          { title: "Estrutura de Banco de Dados", duration: "1h" },
          { title: "Normalização de Banco de Dados", duration: "1h" }
        ]
      },
      {
        title: "Modelagem de Banco de Dados",
        moduleNumber: "2º Módulo",
        topics: [
          { title: "Modelagem Conceitual", duration: "1h" },
          { title: "Modelagem Lógica", duration: "1h" },
          { title: "Modelagem Física", duration: "1h" },
          { title: "Projetos de Modelagem", duration: "1h" }
        ]
      },
      {
        title: "Linguagem SQL",
        moduleNumber: "3º Módulo",
        topics: [
          { title: "Funções de Criação e Seleção", duration: "1h" },
          { title: "Funções de Atualizar e Apagar", duration: "1h" },
          { title: "Funções de Agregação e Agrupamento", duration: "1h" },
          { title: "Constrains", duration: "1h" }
        ]
      },
      {
        title: "Projetos de Banco de Dados",
        moduleNumber: "4ª Módulo",
        topics: [{ title: "Projetos de Banco de Dados", duration: "8h" }]
      },
    ],
    instructor: {
      name: 'Sullivan Lima',
      description: 'Administrador, Consultor em Gestão de Processos e Analista de Sistemas',
      image: '/img/Me-About.jpeg',
      description_2: 'Sullivan Lima é formado em Administração e em Análise e Desenvolvimento de Sistemas; Especialista em Gestão de Negócios e Gestão de Projetos; e CEO e Fundador da S & LIMA Consultoria e Assessoria. Possui mais de 10 anos de experiência atuando na melhoria e no mapeamento de processos referentes às áreas de suprimentos, administrativa e financeira.',
      description_3: 'Seu foco é gestão de processos orientada a dados. Possui sólidos conhecimentos em Business Analytics referente a construção de modelos relacionais e geração de relatórios para tomada de decisão.'
    },
    sideBarContent: {
      price: 'R$ 500,00',
      instructorName: 'Sullivan Lima',
      duration: '20 horas',
      numberOfStudents: 'XX'
    }
  },
];

function BancoDeDados(){
	BundleMin()
  bootstrapMin()
  return (
<>
      <div className="container">
        <div className="row">
          <div className="offset-xl-2 col-xl-8 col-md-12 col-12 mt-6">
            <div>
              <h1 className="mb-0 display-4">
                {courses[0].title}<br />
              </h1>
            </div>
          </div>
        </div>
      </div>
      {/* //  <!-- program single tabs --> */}
      <div className="pt-lg-10 pb-lg-16 py-6 ">
        <div className="container">
          <div className="row">
            <div className="col-xl-8 col-lg-8 col-md-7 col-sm-12 col-12 mb-4">
              <div>
                <ul className="nav-pills-border nav nav-pills nav-justified mb-5 " id="pills-tab" role="tablist">
                  <li className="nav-item">
                    <a className="nav-link active fw-bold" id="pills-Overview-tab" data-bs-toggle="pill" href="#pills-Overview" role="tab" aria-controls="pills-Overview" aria-selected="true">Visão Geral</a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link fw-bold" id="pills-Curriculum-tab" data-bs-toggle="pill" href="#pills-Curriculum" role="tab" aria-controls="pills-Curriculum" aria-selected="false">Ementa</a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link fw-bold" id="pills-Coach-tab" data-bs-toggle="pill" href="#pills-Coach" role="tab" aria-controls="pills-Coach" aria-selected="false">Instrutor</a>
                  </li>
                </ul>
                <div className="tab-content" id="pills-tabContent">
                  <OverviewComponent serviceContent={courses[0].overviewContent} />
                  <Ementa modules={courses[0].ementaModules} />
                  <DadosInstrutor instructor={courses[0].instructor} />
                </div>
              </div>
            </div>
            {/* <!-- sidebar --> */}
            <SideBarComponent
              preco={courses[0].sideBarContent.price}
              nome_instrutor={courses[0].sideBarContent.instructorName}
              duracao={courses[0].sideBarContent.duration}
              n_inscritos={courses[0].sideBarContent.numberOfStudents}
            />
          </div>
        </div>
      </div>
    </>
  )
}


export default BancoDeDados;
