import React from 'react'
import Contato from './contato';

function Planejamento() {

  return (
    <>
      <div class="container">
        <div class="row">
          <div class="offset-xl-2 col-xl-8 col-md-12 col-12">
            <div class="row align-items-center mb-12">
              <div class="col-md-8 col-12">

                <h2 class="mb-2 mt-8 display-5">Análise de Negócios e Elaboração de Planejamentos</h2>
                <p class=" mb-0 font-18">Com uma abordagem inovadora e centrada no cliente, nossa consultoria oferece soluções que não apenas atendem, mas antecipam as necessidades do mercado. Nosso compromisso com a excelência e nossa vasta experiência nos colocam na vanguarda da transformação digital, liderando o caminho rumo ao sucesso empresarial. Nós ultrapassamos os limites do desenvolvimento de sistemas, alinhando-os perfeitamente aos três níveis de planejamento: Estratégico, Tático e Operacional</p>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-4 col-12">

            <div class="img-overlay img-zoom mb-4 mb-md-0 border-top border-5
              border-secondary rounded-3">
              <a href="#"><img class="img-fluid w-100" src="img/img-estrategico.png" alt="" />
                <div class="position-absolute bottom-0 text-white d-flex
                  flex-column
                  justify-content-end p-4 bg-dark-70">

                  <h3 class="mb-3 text-white">Nível Estratégico</h3>
                  <span>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0
                      24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-play-circle
                      me-2"><circle cx="12" cy="12" r="10"></circle><polygon points="10 8 16 12 10 16 10 8"></polygon></svg>Consultoria e Desenvolvimento </span>
                </div>
              </a>
            </div>

          </div>
          <div class="col-md-4 col-12">

            <div class="img-overlay img-zoom mb-4 mb-md-0 rounded-3
              border-top border-5 border-warning">
              <a href="#" class="text-white">
                <img src="/img/tatico.jpg" alt="" class="img-fluid w-100" />
                <div class="position-absolute bottom-0 text-white d-flex
                  flex-column
                  justify-content-end p-4 bg-dark-70">

                  <h3 class="mb-3 text-white">Nível Tático</h3>
                  <span>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0
                      24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-play-circle
                      me-2"><circle cx="12" cy="12" r="10"></circle><polygon points="10 8 16 12 10 16 10 8"></polygon></svg>Consultoria e Treinamento </span>
                </div>
              </a>
            </div>
          </div>
          <div class="col-md-4 col-12">

            <div class="img-overlay img-zoom mb-4 mb-md-0 rounded-3 border-top
              border-5 border-success">
              <a href="#" class="text-white">
                <img src="img/post-thumb-img-8.jpg" alt="" class="img-fluid w-100" />
                <div class="position-absolute bottom-0 d-flex flex-column
                  justify-content-end p-4 bg-dark-70">
                  <h3 class="mb-3 text-white">Nível Operacional</h3>
                  <span>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0
                      24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-play-circle
                      me-2"><circle cx="12" cy="12" r="10"></circle><polygon points="10 8 16 12 10 16 10 8"></polygon></svg>Consultoria e Treinamento</span>
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
      <div class="mt-6 py-lg-16 py-12 bg-light mb-8">
        <div class="container">
          <div class="row">
            <div class="offset-xl-2 col-xl-5 col-lg-7 col-md-12 col-12">
              <div class="mb-12">

                <h1 class="mb-4 display-5">Por que nossa consultoria?</h1>

                <p class="font-18 mb-0">Nossa consultoria é fundamentada em anos de experiência prática e conhecimento especializado em análise de dados e sistemas de gestão. Ao trabalhar conosco, você terá acesso a insights valiosos e orientação estratégica que o ajudarão a tomar decisões informadas e a alcançar seus objetivos empresariais de forma eficaz.</p>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6 col-12">

              <div class="card rounded-3 mb-5">

                <div class="p-5 d-flex justify-content-between
                align-items-center">
                  <div class="">

                    <h4 class="mb-1">Garantia de Qualidade</h4>
                    <p class="mb-0">Garantia da Qualidade é integrada em todos os níveis de planejamento, assegurando excelência e liderança na transformação digital.</p>
                  </div>
                  <div class="">

                    <svg xmlns="http://www.w3.org/2000/svg" width="34" height="34" viewBox="0 0 24
                    24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather
                    feather-award text-primary"><circle cx="12" cy="8" r="7"></circle><polyline points="8.21 13.89 7 23 12 20 17 23
                      15.79 13.88"></polyline></svg>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-6 col-12">

              <div class="card rounded-3 mb-5">
                <div class="p-5 d-flex justify-content-between
                align-items-center">

                  <div class="">
                    <h4 class="mb-1">Anos de Experiência</h4>

                    <p class="mb-0">Nossa excelência é respaldada por anos de experiência no mercado impulsionando soluções inovadoras e confiáveis para sua empresa.</p>
                  </div>
                  <div class="">

                    <svg xmlns="http://www.w3.org/2000/svg" width="34" height="34" viewBox="0 0 24
                    24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather
                    feather-coffee text-primary"><path d="M18 8h1a4 4 0 0 1 0 8h-1"></path><path d="M2 8h16v9a4 4 0 0 1-4 4H6a4 4 0 0
                      1-4-4V8z"></path><line x1="6" y1="1" x2="6" y2="4"></line><line x1="10" y1="1" x2="10" y2="4"></line><line x1="14" y1="1" x2="14" y2="4"></line></svg>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-6 col-12">

              <div class="card rounded-3 mb-5">
                <div class="p-5 d-flex justify-content-between
                align-items-center">

                  <div class="">
                    <h4 class="mb-1">Realização de Treinamento</h4>

                    <p class="mb-0">Oferecemos um treinamento que vai além do ensino, capacitando equipes para alcançar seu potencial máximo. Transformamos você e seus colaboradores.</p>
                  </div>
                  <div class="">


                    <svg xmlns="http://www.w3.org/2000/svg" width="34" height="34" viewBox="0 0 24
                    24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather
                    feather-video text-primary"><polygon points="23 7 16 12 23 17 23 7"></polygon><rect x="1" y="5" width="15" height="14" rx="2" ry="2"></rect></svg>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-6 col-12">

              <div class="card rounded-3 mb-5">
                <div class="p-5 d-flex justify-content-between
                align-items-center">
                  <div class="">

                    <h4 class="mb-1">Humanização</h4>

                    <p class="mb-0">A humanização está no cerne de tudo o que fazemos na S & LIMA, criando soluções tecnológicas com empatia e propósito.</p>
                  </div>
                  <div class="">


                    <svg xmlns="http://www.w3.org/2000/svg" width="34" height="34" viewBox="0 0 24
                    24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather
                    feather-heart text-primary"><path d="M20.84 4.61a5.5 5.5 0 0 0-7.78
                      0L12 5.67l-1.06-1.06a5.5 5.5 0 0
                      0-7.78 7.78l1.06 1.06L12
                      21.23l7.78-7.78 1.06-1.06a5.5 5.5 0
                      0 0 0-7.78z"></path></svg>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-6 col-12">
              <div class="card rounded-3 mb-5">
                <div class="p-5 d-flex justify-content-between
                align-items-center">
                  <div class="">
                    <h4 class="mb-1">Prática &amp; Teoria</h4>
                    <p class="mb-0">Unimos prática e teoria para oferecer soluções de desenvolvimento de sistemas fundamentadas na experiência e na inovação.</p>
                  </div>
                  <div class="">
                    <svg xmlns="http://www.w3.org/2000/svg" width="34" height="34" viewBox="0 0 24
                    24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather
                    feather-sun text-primary"><circle cx="12" cy="12" r="5"></circle><line x1="12" y1="1" x2="12" y2="3"></line><line x1="12" y1="21" x2="12" y2="23"></line><line x1="4.22" y1="4.22" x2="5.64" y2="5.64"></line><line x1="18.36" y1="18.36" x2="19.78" y2="19.78"></line><line x1="1" y1="12" x2="3" y2="12"></line><line x1="21" y1="12" x2="23" y2="12"></line><line x1="4.22" y1="19.78" x2="5.64" y2="18.36"></line><line x1="18.36" y1="5.64" x2="19.78" y2="4.22"></line></svg>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-6 col-12">
              <div class="card rounded-3 mb-5">
                <div class="p-5 d-flex justify-content-between
                align-items-center">
                  <div class="">
                    <h4 class="mb-1">Suporte Motivacional</h4>
                    <p class="mb-0">Nosso suporte motivacional na S & LIMA vai além do técnico, impulsionando equipes para alcançarem seus objetivos com confiança e determinação.</p>
                  </div>
                  <div class="">
                    <svg xmlns="http://www.w3.org/2000/svg" width="34" height="34" viewBox="0 0 24
                    24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather
                    feather-loader text-primary"><line x1="12" y1="2" x2="12" y2="6"></line><line x1="12" y1="18" x2="12" y2="22"></line><line x1="4.93" y1="4.93" x2="7.76" y2="7.76"></line><line x1="16.24" y1="16.24" x2="19.07" y2="19.07"></line><line x1="2" y1="12" x2="6" y2="12"></line><line x1="18" y1="12" x2="22" y2="12"></line><line x1="4.93" y1="19.07" x2="7.76" y2="16.24"></line><line x1="16.24" y1="7.76" x2="19.07" y2="4.93"></line></svg>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Planejamento;