import React from 'react'

function NumerosIndex(){

  return(
    <>
    <div class="container">
    <div class="row g-4">
		<h2 class="mb-0 mt-8 text-dark text-center">Explore nossos cursos especializados</h2>
		<p class="mb-0 mt-2 text-center">
			Pessoas que já realizaram nossos treinamentos voltados para todo o campo profissional das áreas de dados e desenvolvimento de softwares
		</p>
			{/* <!-- Counter item --> */}
			<div class="col-sm-6 col-xl-3">
				<div class="d-flex justify-content-center align-items-center p-4 bg-success bg-opacity-15 rounded-3">
					<span class="display-6 lh-1 text-white mb-0"><i class="fas fa-tv"></i></span>
					<div class="ms-4 h6 fw-normal mb-0 text-white">
						<div class="d-flex">
							<h5 class="purecounter mb-0 fw-bold text-white" data-purecounter-start="0" data-purecounter-end="10" data-purecounter-delay="200" data-purecounter-duration="0">10</h5>
							<span class="mb-0 h5 text-white">K</span>
						</div>
						<p class="mb-0">Estão Cursando</p>
					</div>
				</div>
			</div>
			{/* <!-- Counter item --> */}
			<div class="col-sm-6 col-xl-3">
				<div class="d-flex justify-content-center align-items-center p-4 bg-blue bg-opacity-10 rounded-3">
					<span class="display-6 lh-1 text-blue mb-0"><i class="fas fa-user-tie"></i></span>
					<div class="ms-4 h6 fw-normal mb-0">
						<div class="d-flex">
							<h5 class="purecounter mb-0 fw-bold text-dark" data-purecounter-start="0" data-purecounter-end="200" data-purecounter-delay="200" data-purecounter-duration="0">10</h5>
							<span class="mb-0 h5">+</span>
						</div>
						<p class="mb-0">Especialistas Lecionando</p>
					</div>
				</div>
			</div>
			{/* <!-- Counter item --> */}
			<div class="col-sm-6 col-xl-3">
				<div class="d-flex justify-content-center align-items-center p-4 bg-purple bg-opacity-10 rounded-3">
					<span class="display-6 lh-1 text-purple mb-0"><i class="fas fa-user"></i></span>
					<div class="ms-4 h6 fw-normal mb-0 text-muted">
						<div class="d-flex">
							<h5 class="purecounter mb-0 fw-bold text-muted" data-purecounter-start="0" data-purecounter-end="60" data-purecounter-delay="200" data-purecounter-duration="0">60</h5>
							<span class="mb-0 h5 text-muted">K+</span>
						</div>
						<p class="mb-0">Estão Cursando</p>
					</div>
				</div>
			</div>
			{/* <!-- Counter item --> */}
			<div class="col-sm-6 col-xl-3">
				<div class="d-flex justify-content-center align-items-center p-4 bg-info bg-opacity-10 rounded-3">
					<span class="display-6 lh-1 text-dark mb-0"><i class="fas fa-user-graduate"></i></span>
					<div class="ms-4 h6 fw-normal mb-0">
						<div class="d-flex">
							<h5 class="purecounter mb-0 fw-bold" data-purecounter-start="0" data-purecounter-end="6" data-purecounter-delay="300" data-purecounter-duration="0">6</h5>
							<span class="mb-0 h5">K+</span>
						</div>
						<p class="mb-0">Concluíram</p>
					</div>
				</div>
			</div>
		</div>
    </div>
    </>
  )
}

export default NumerosIndex;