import React, { useState, useEffect } from 'react';

function BackToTop() {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    // Adicione um event listener para detectar a rolagem da página
    const handleScroll = () => {
      // Verifique a posição da rolagem da página
      if (window.scrollY > 100) { // Altere 100 para o valor desejado para mostrar o botão
        setIsVisible(true); // Se a página for rolada para baixo, mostre o botão
      } else {
        setIsVisible(false); // Caso contrário, oculte o botão
      }
    };

    // Adicione o event listener ao montar o componente
    window.addEventListener('scroll', handleScroll);

    // Remova o event listener ao desmontar o componente
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handleBackToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth' // Rola suavemente para o topo
    });
  };

  return (
    <>
      {isVisible && (
        <div className="back-top back-top-show" onClick={handleBackToTop}>
          <i className="fa fa-arrow-up position-absolute top-50 start-50 translate-middle text-white"></i>
        </div>
      )}
    </>
  );
}

export default BackToTop;
