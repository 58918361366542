import React from 'react'

function Ementa({ modules }) {
  return (
    <div className="tab-pane fade" id="pills-Curriculum" role="tabpanel" aria-labelledby="pills-Curriculum-tab">
      <div>
        {modules.map((module, index) => (
          <div className="card mb-4" key={index}>
            <div className="card-body p-5">
              <div className="d-lg-flex justify-content-between align-items-center mb-2">
                <div>
                  <h4 className="mb-1">{module.title}</h4>
                </div>
                <div>
                  <h5 className="text-primary text-uppercase">{module.moduleNumber}</h5>
                </div>
              </div>
              <ul className="list-group list-group-flush">
                {module.topics.map((topic, idx) => (
                  <li className="list-group-item px-0" key={idx}>
                    <div className="d-md-flex justify-content-between align-items-center">
                      <h5 className="mb-0">{topic.title}</h5>
                      <span className="mb-0">{topic.duration}</span>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Ementa;
