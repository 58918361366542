import React from 'react'

function BIServices() {
    return (
        <>
            <div class="pt-lg-12 pb-lg-12 py-8 bg-shape" >
                <div class="container">
                    <div class="row">
                        <div class="col-lg-5 col-md-12 col-12">
                            <div class="mb-8">
                                <h2 class="h1 mb-3 text-center">Obtenha o Máximo do Potencial dos Seus Dados com Projetos de Power BI</h2>
                                <p class="text-center font-18">
                                    Em um mundo cada vez mais movido por dados, a capacidade de extrair <i>insights</i> valiosos e acionáveis, de forma rápida, é essencial para impulsionar o sucesso do seu negócio. Na S&LMA, oferecemos serviços especializados em projetos de Power BI, capacitando sua empresa a explorar todo o potencial dos seus dados por meio de dashboards interativos e análises de indicadores.
                                </p>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-12 border-4 rounded-2">
                            <img src="/img/Etica-Empresarial.jpg" alt="" />
                        </div>
                    </div>
                </div>
                <div class="container-fluid mb-8">
                    <div class="row d-flex align-items-center ">
                        <div class="col-xl-9 col-lg-9 col-12 ps-lg-0">
                            <div>
                                <img src="/img/Img-BI.png" alt="" class="img-fluid" />
                            </div>
                        </div>
                        <div class="offset-xl-7 col-xl-4 offset-lg-5 col-lg-6 col-12 ps-lg-0">
                            <div class="card  border-0 shadow mt-xl-n23 mt-lg-n23 mt-n5">
                                <div class="card-body p-7 border-top border-primary border-4 rounded-top">
                                    <h3 class="mb-4">
                                        Benefícios e Vantagens
                                    </h3>
                                    <p>
                                        Você contará com nossa expertise em ciência de dados e o poder do Power BI, além, é claro, da sólida experiência com análise de indicadores dos mais variados setores empresariais. Estamos prontos para impulsionar sua empresa rumo ao sucesso, capacitando-a a tomar decisões mais inteligentes e estratégicas.
                                    </p>
                                    <p>
                                        Entre em contato conosco hoje mesmo e descubra como nossos serviços de projetos de Power BI podem transformar seus dados em <i>insights</i> valiosos e conduzir sua empresa ao próximo nível.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                <div class="container mt-lg-14 mb-lg-10">
                    <div class="row">
                        <div class="col-lg-3 col-md-6 col-12">
                            <div class="card mb-lg-0 mb-4 border-0">
                                <div class="card-body p-4 ">
                                    <i class="fas fa-chart-line fa-fw text-primary font-28 mb-4 text-center"></i>
                                    <h4 className='text-center'>Visualização Intuitiva</h4>
                                    <p class="mb-0 text-center">
                                        Visualizações dinâmicas e fáceis de entender, permitindo que você identifique padrões, tendências e oportunidades de negócios de forma instantânea.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-6 col-12">
                            <div class="card mb-lg-0 mb-4 border-0">
                                <div class="card-body p-4">
                                    <i class="fas fa-mountain fa-fw text-primary font-28 mb-4 text-center"></i>
                                    <h4 className='text-center'>Tomada de Decisão</h4>
                                    <p class="mb-0 text-center">
                                        Baseada em dados fornecendo insights acionáveis ​​em tempo real, essenciais para direcionar estratégias de negócios e maximizar resultados.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-6 col-12">
                            <div class="card mb-lg-0 mb-4 border-0">
                                <div class="card-body p-4">
                                    <i class="fas fa-trophy fa-fw text-primary font-28 mb-4"></i>
                                    <h4 className='text-center'>Personalização</h4>
                                    <p class="mb-0 text-center">
                                        Nossos projetos de Power BI são altamente personalizados para atender às necessidades exclusivas da sua empresa oferecendo flexibilidade para adaptar e evoluir.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-6 col-12">
                            <div class="card mb-lg-0 mb-4 border-0">
                                <div class="card-body p-4">
                                    <i class="fas fa-star-half-alt fa-fw text-primary font-28 mb-4 text-center"></i>
                                    <h4 className='text-center'>Experiência</h4>
                                    <p class="mb-0 text-center">
                                        Ao adotar uma abordagem baseada em dados, você ganha uma vantagem competitiva significativa e isso alinhado com a experiência dos nossos profissionais.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default BIServices;