import React from 'react';
import { useNavigate } from 'react-router-dom';

function HeaderStudent() {
  const navigate = useNavigate();

  const handleLogout = () => {
    // Aqui você pode adicionar a lógica de logout, como limpar os tokens de autenticação, etc.
    // Após o logout, redirecione o usuário de volta para a página inicial
    navigate('/');
  };

  return (
    <>
          <div className="header fixed-top border-4 border-top border-primary">
        {/* <!-- navigation start --> */}
        <div className="container">
          <nav className="navbar navbar-expand-lg navbar-default">
            <a className="navbar-brand" href="/"><img src="/img/Frame.svg" width="120" alt="" /></a>
            <button className="navbar-toggler collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#navbar-default" aria-controls="navbar-default" aria-expanded="false" aria-label="Toggle navigation">
              <span className="icon-bar top-bar mt-0"></span>
              <span className="icon-bar middle-bar"></span>
              <span className="icon-bar bottom-bar"></span>
            </button>
            <div className="collapse navbar-collapse" id="navbar-default">
              <button className="navbar-toggler collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#navbar-default" aria-controls="navbar-default" aria-expanded="false" aria-label="Toggle navigation">
                <i className="fas fa-times"></i>
              </button>
              <ul className="navbar-nav ms-auto me-lg-3">
                <li className="nav-item dropdown">
                  <a className="nav-link dropdown-toggle" href="#" id="menu-5" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    <i className="fa fa-desktop"></i>
                    Minhas Salas Virtuais
                  </a>
                  <ul className="dropdown-menu dropdown-menu-arrow  dropdown-menu-xl-start " aria-labelledby="menu-5">
                    <li className="dropdown-submenu">
                      <a className="dropdown-item" href="../classroom/classroom.html">
                        <i className="fa fa-chalkboard"></i>
                        Curso - Segurança da Informação
                      </a>
                    </li>
                  </ul>
                </li>
                <li className="nav-item dropdown">
                  {/* Adicionando o evento onClick para chamar a função handleLogout */}
                  <a className="nav-link" href="#" id="menu-2" aria-haspopup="true" aria-expanded="false" onClick={handleLogout}>
                  <i class="fa fa-sign-out-alt"></i>
                  Desconectar
                  </a>
                </li>
              </ul>
            </div>
          </nav>
        </div>
      </div>
    </>
  )
};

export default HeaderStudent;