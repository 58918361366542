import React from 'react'

function Process() {
  return (
    <>
      <div className='container'>
        <div class="row">
          <div class="overflow-hidden gradient-x-three-sm-primary rounded-2 py-6 py-lg-12 py-xxl-16">
            <div class="container content-space-2 content-space-lg-3">
              <div class="row justify-content-center align-items-lg-center">
                <div class="col-10 col-sm-8 col-lg-5 mb-10 mb-lg-0">
                  <div class="position-relative">
                    <img class="img-fluid rounded-2" src="/img/mapeamento-processos.jpg" alt="Image Description" />
                    <div class="position-absolute top-0 start-0 zi-n1 mt-n6 ms-n7" style={{ width: "10rem;" }}>
                      <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 335.2 335.2" width="100" height="100">
                        <circle fill="#6a0dad" opacity=".7" cx="167.6" cy="167.6" r="130.1"></circle>
                      </svg>
                    </div>
                    <div class="position-absolute bottom-0 end-0 zi-n1 mb-n6 me-n10" style={{ width: "10rem;" }}>
                      <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 335.2 335.2" width="120" height="120">
                        <circle fill="none" stroke="#030190" stroke-width="75" cx="167.6" cy="167.6" r="130.1"></circle>
                      </svg>
                    </div>
                  </div>
                </div>
                <div class="col-lg-7">
                  <div class="ps-lg-6">
                    <div class="mb-3">
                      <h2 className='mb-2 mt-8 display-6'>Mapeamento de Processos para o Sucesso Empresarial</h2>
                    </div>
                    <figure class="mb-5">
                      <blockquote class="blockquote blockquote-lg">Imagine sua empresa como uma máquina bem ajustada, onde cada engrenagem se move harmoniosamente em sincronia para impulsionar o sucesso. Isso é o que o mapeamento de processos faz. Ao visualizar e analisar cada etapa do seu fluxo de trabalho, podemos identificar áreas de ineficiência, gargalos e oportunidades de melhoria.</blockquote>
                    </figure>
                    <div class="row">
                      <div class="col-sm-6 col-md-6 mb-3 mb-sm-0">
                        <h2 class="text-primary mb-1">Impacto nos Resultados</h2>
                        <p class="small mb-0"> Ao otimizar seus processos internos, você reduz custos, aumenta a produtividade e melhora a qualidade do seu produto ou serviço. Isso se traduz diretamente em maior lucratividade e vantagem competitiva</p>
                      </div>
                      <div class="col-sm-8 col-md-6">
                        <h2 class="text-primary mb-1">Desafie-se a ser Melhor</h2>
                        <p class="small mb-0">Cada empresa tem o potencial de se tornar excepcional. O mapeamento de processos não apenas revela áreas de melhoria, mas também inspira uma cultura de contínua inovação e excelência.</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </>
  )
}
export default Process;