import React from 'react'

function SectionTwoPort() {
  return (
    <>
      <div className='presentation-page mt-8'>
        <div class="section section-cards">
          <div class="container">
            <h2 class="text-center title">Inovação, Eficiência e Adaptabilidade</h2>
            <p class="text-center">
              Nossa abordagem flexível nos permite ajustar e evoluir conforme as necessidades e os objetivos do cliente ao longo do tempo. Reconhecemos que o ambiente empresarial está em constante mudança, e estamos preparados para adaptar nossas soluções de acordo com as demandas do mercado e as necessidades em evolução de nossos clientes.
            </p>
            <div class="row">

            </div>
            <div class="col-md-8 text-center ml-auto mr-auto">
            </div>
            <div class="row">
              <div class="col-md-12">
                <div class="images-container">
                  <div class="image-container1">
                    <img src="/img/card3.webp" />
                  </div>
                  <div class="image-container2">
                    <img src="/img/card6.jpg" />
                  </div>
                  <div class="image-container3">
                    <img src="/img/card7.jpg" />
                  </div>
                  <div class="image-container4">
                    <img src="/img/card5.jpg" />
                  </div>
                  <div class="image-container5">
                    <img src="/img/card4.jpg" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default SectionTwoPort;