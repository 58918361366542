import React, { useState } from 'react';

function Contato() {
  const [formData, setFormData] = useState({
    programFormName: '',
    programFormEmail: '',
    message: ''
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch('http://localhost:5000/api/send-email', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(formData)
      });
      if (response.ok) {
        alert('E-mail enviado com sucesso!');
      } else {
        alert('Erro ao enviar o e-mail.');
      }
    } catch (error) {
      alert('Erro ao enviar o e-mail.');
    }
  };

  return (
    <section className="pt-0 pb-0">
      <div className="container">
        <div className="row">
          <div className="offset-lg-2 col-lg-8 col-md-12 col-12 mb-5">
            <div className="card z-1">
              <div className="card-body shadow p-4 p-lg-9">
                <div className="mb-6 text-center">
                  <h2 className="mb-3" id="contato">Nosso Contato</h2>
                  <p>
                    Deixe uma mensagem em nosso whatsapp <strong>(21) 9.9190-2105 </strong> para saber mais.
                    <br />Logo entraremos em contato com você com a melhor proposta para sua necessidade
                  </p>
                </div>
                {/* <form className="row g-3 needs-validation" onSubmit={handleSubmit}>
                  <div className="col-12 col-md-6">
                    <label htmlFor="programFormName" className="form-label">Nome</label>
                    <input
                      type="text"
                      className="form-control"
                      id="programFormName"
                      placeholder="Nome"
                      name="programFormName"
                      value={formData.programFormName}
                      onChange={handleChange}
                      required
                    />
                    <div className="invalid-feedback">Por Favor, entre com seu nome.</div>
                  </div>
                  <div className="col-12 col-md-6">
                    <label htmlFor="programFormEmail" className="form-label">Email</label>
                    <input
                      type="email"
                      className="form-control"
                      id="programFormEmail"
                      placeholder="Email"
                      name="programFormEmail"
                      value={formData.programFormEmail}
                      onChange={handleChange}
                      required
                    />
                    <div className="invalid-feedback">Por favor, entre com seu e-mail.</div>
                  </div>
                  <div className="col-12">
                    <label htmlFor="message" className="form-label">Mensagem</label>
                    <textarea
                      className="form-control"
                      id="message"
                      name="message"
                      cols="30"
                      rows="10"
                      value={formData.message}
                      onChange={handleChange}
                      required
                    ></textarea>
                    <div className="invalid-feedback">Por favor, entre com sua mensagem.</div>
                  </div>
                  <div className="col-12">
                    <div className="d-grid">
                      <button type="submit" className="btn btn-primary">Enviar</button>
                    </div>
                  </div>
                  <div className="col-12">
                    <p className="text-muted mb-0 small">
                      Aviso...
                      <a href="#" className="text-secondary fw-semi-bold">Política de Privacidade.</a>
                      <a href="#" className="text-secondary fw-semi-bold"> Nossos Termos</a>
                    </p>
                  </div>
                </form> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Contato;
