import React from 'react';


function PasswordReset(){
return (
<>
<div class="min-vh-100 d-flex align-items-center header">
        <div class="container">
            <div class="row">
                <div class="offset-lg-3 col-lg-6 col-12">
                    <a href="/" class="mb-4 d-flex justify-content-center">
                        <img src="/img/Frame.svg" alt="" width="260" /></a>

                    <div class="bg-dark p-4 p-lg-8 rounded-3">
                        <form>
                            <h1 class="mb-2 text-white h3">Redefinição de Senha</h1>
                            <p class="mb-4 text-muted">Insira seu e-mail cadastrado e redefina sua senha</p>

                            <div class="mb-3">
                                <label for="email" class="form-label  text-white-50">E-mail de Acesso </label>
                                <input type="email" id="email" class="form-control border-0" placeholder="Seu-Email@enderecodeemail.com" required="" />
                            </div>
<div class="d-grid">
                            <button class="btn btn-primary" type="submit">
                  Redefinir Senha
                </button>
            </div>
                            <p class="mt-4 mb-3 text-white font-14">
                                Lembrou sua senha ?<a href="/login" className='text-white btn-destac'>Acessar</a>
                            </p>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</>
)}
export default PasswordReset;