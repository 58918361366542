import React from 'react'
import PersonalChief from './teampersonchief';
import Afiliado from './teamafiliado';

function Team(){
  return(
    <>

      {/* //  <!-- Team--> */}
      <div class="pt-lg-4 pb-lg-3 py-6">
        <div class="container">
          <div class="row">
            <div class="col-lg-12 col-md-12 col-12">
              <div class="mb-6">
                <h2>Nossos Líderes</h2>
              </div>
            </div>
            </div>
            </div>
      <div class="pt-lg-4 pb-lg-3 py-6">
        <div class="container">
          <div class="row">
            <PersonalChief 
              nome="Sullivan Lima"
              description="Administrador e Desenvolvedor, CEO and Founder"
              foto='/img/ceo.jpeg'
              texto='Descrição Minha'/>
              </div>
              </div>
            </div>
          </div>
      <div class="py-6">
        <div class="container">
          <div class="row">
            <div class="col-lg-12 col-md-12 col-12">
              <div class="mb-6">
                <h2>Nossos parceiros</h2>
              </div>
            </div>
            <Afiliado 
            nomeAfiliado='Matheus Brum'
            imagemAfiliado='/img/mt-brum.png'
            profissaoAfiliado="Desenvolvedor Web & Mobile"/>
            <Afiliado 
            nomeAfiliado='Nadillaine Dayse'
            imagemAfiliado='/img/deise.png'
            profissaoAfiliado="Comercial & Digital"/>

          <Afiliado 
            nomeAfiliado='Eduardo Lima'
            imagemAfiliado='/img/team-Eduardo.png'
            profissaoAfiliado="Assuntos Jurídicos"/>

          <Afiliado 
            nomeAfiliado='Raíssa França'
            imagemAfiliado='/img/team-raissa.png'
            profissaoAfiliado="Produção e Revisão Textual"/>
          </div>
        </div>
      </div>
      
</>
  )
}

export default Team;