import React from 'react';

function Portifolio() {
  return (
    <>
      <div class="section">
        <div class="inner-container-small text-center mb-8 mt-8 ">
          <h5 class="text-center">
            <h2 class="text-center title">Transformando Ideias em Tecnologia</h2>
          </h5>
        </div>
        <div class="section">
          <div class="container">
            <div class="row">
              <div class="col-md-4 text-center">
                <div class="display-6 text-light text-blue mb-0">
                  <i class="fa fa-robot"></i>
                </div>

                <h4 class="info-title">Moderno</h4>
                <p>Acreditamos que o desenvolvimento de software não é apenas sobre escrever código, mas sim sobre criar soluções que impulsionem o sucesso empresarial</p>
              </div>
              <div class="col-md-4 text-center">
                <div class="display-6 text-success mb-0">
                  <i class="fa fa-expand"></i>
                </div>
                <h4 class="info-title">Responsivo</h4>
                <p>Design responsivo garante uma experiência consistente e adaptativa. Nossos modelos se adaptam dinamicamente a diferentes tamanhos de tela</p>

              </div>
              <div class="col-md-4 text-center">
                <div class="display-6 text-info mb-0">
                  <i class="fa fa-brush text-center"></i>
                </div>

                <h4 class="info-title">Customizável</h4>
                <p>Nosso estilo é moldado por anos de experiência e compromisso com a excelência, proporcionando às empresas uma abordagem única e personalizada</p>

              </div>
            </div>
          </div>
        </div>
        <div class="container">
          <div class="row">
            <div class="col-md-3">
              <div class="card-container first-card">
                <div class="card-component">
                  <a href="index.html#basic-elements">
                    <div class="front">
                      <img src="/img/basic_thumb.jpg" />
                    </div>
                  </a>
                </div>
              </div>
            </div>
            <div class="col-md-3">
              <div class="card-container second-card">
                <div class="card-component">
                  <a href="index.html#cards">
                    <div class="front">
                      <img src="/img/cards_thumb.jpg" />
                    </div>
                    <div class="overlay">
                      <div class="overlay-text">
                        <h3>Cards </h3>
                        <p className='text-dark'>Construa suas páginas web personalizadas e divulgue seus trabalhos de forma profissional e independente</p>
                      </div>
                    </div>
                  </a>
                </div>
              </div>
            </div>
            <div class="col-md-3">
              <div class="card-container third-card">
                <div class="card-component">
                  <a href="sections.html">
                    <div class="front">
                      <img src="/img/sections_thumb.jpg" />
                    </div>
                  </a>
                </div>
              </div>
            </div>
            <div class="col-md-3">
              <div class="card-container fourth-card">
                <div class="card-component">
                  <a href="examples/product-page.html">
                    <div class="front">
                      <img src="/img/pages2_thumb.jpg" />
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Portifolio;