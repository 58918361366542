import React, { useState, useEffect } from 'react';
import { init } from 'ityped';


function CeoSection() {

    return (
        <section className="bg-dark-gradient right-slant-shape pt-lg-16 py-12 pb-lg-0">
            <div className="container">
                <div className="row d-lg-flex align-items-center">
                    <div className="col-xxl-5 col-md-6 col-12">
                        <div className="mb-0 mb-lg-8">
                            <span className="cd-headline clip big-clip is-full-width text-primary mb-0 d-block d-xxl-inline-block" />

                            <h1 className="typed display-5 text-white mb-3"> {/*bubble-text text */}
                                {/* {splitText} */}Transformando Ideias em Realidade Digital
                            </h1>
                            <p className="mb-4 lead">
                                "Oferecemos consultoria empresarial e desenvolvimento de softwares personalizados para atender às necessidades específicas de sua empresa. Nosso objetivo é impulsionar o crescimento e o desempenho do seu negócio, fornecendo soluções sob medida que alinham estratégia, tecnologia e excelência operacional"</p>
                            <a href="#" className="btn btn-primary me-3">Contato</a>
                            <a className="popup-youtube icon-shape rounded-circle btn-play icon-md bg-secondary text-white me-1 text-decoration-none font-12" href="#VideoInstitucional">
                                <i className="fas fa-play"></i>
                            </a>
                            <a href="#Video" className="popup-youtube text-white">Apresentação</a>
                        </div>
                    </div>
                    <div className="offset-xxl-2 col-xxl-5 offset-lg-1 col-lg-5 col-md-6 col-12 mb-n2 d-none d-md-block">
                        <div className="position-relative z-1">
                            <div className="d-inline-block card card-body shadow rounded-4 position-absolute end-0 top-0 p-3 me-lg-n3 me-xxl-n7 mt-n5 d-none d-sm-block">
                                <div className="d-flex align-items-center">
                                    <img src="/img/trustpilot-star 1.svg" className="h-30px" alt="rating-img" style={{ width: '100px' }} />
                                    <h6 className="font-base fw-bold ms-1 mb-0">4.8/5.0</h6>
                                </div>
                                <p className="small mb-0 mt-2">Avaliação dos Clientes</p>
                            </div>
                            <img src="/img/ceo.jpeg" alt="img" className="img-fluid rounded w-100 border-bottom border-4 border-primary img-shadow" />
                            <div className="d-inline-block bg-dark rounded-4 position-absolute start-0 bottom-0 mb-md-4 ms-md-n5 p-3" style={{ borderRadius: '0.25rem' }}>
                                <div className="d-flex align-items-center">
                                    <h5 className="text-white mb-0 me-2">1k+</h5>
                                    <ul className="avatar-group mb-0 align-items-center minhalista">
                                        <li className="avatar avatar-xs">
                                            <img className="avatar-img rounded-circle" src="/img/Membro-Eduardo.jpg" alt="avatar" />
                                        </li>
                                        <li className="avatar avatar-xs">
                                            <img className="avatar-img rounded-circle" src="/img/team-raissa.png" alt="avatar" />
                                        </li>
                                        <li className="avatar avatar-xs">
                                            <img className="avatar-img rounded-circle" src="/img/03.jpg" alt="avatar" />
                                        </li>
                                        <li className="avatar avatar-xs">
                                            <img className="avatar-img rounded-circle" src="/img/04.jpg" alt="avatar" />
                                        </li>
                                        <li className="avatar avatar-xs">
                                            <img className="avatar-img rounded-circle" src="/img/gallery-img-1.jpg" alt="avatar" />
                                        </li>
                                    </ul>
                                </div>
                                <h4 className="mb-0 h2 text-white">Sullivan Lima</h4>
                                <span className="font-12 text-muted">CEO and Founder</span>
                            </div>
                        </div>
                        <div className="position-relative z-1">
                            <div className="position-absolute ms-n14 mt-n7">
                                <img src="../dist/assets/img/shape.svg" alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default CeoSection;
