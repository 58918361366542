import React from 'react'
import MenuProfile from './menuProfile';

function Notificacoes(){
  return(
    <>
        <div class="bg-shape bg-dark">
        <div class="container">
            <div class="row">
                <div class="col-xl-12 col-lg-12 col-md-12 col-12">
                    <div class="pt-lg-8 pb-lg-12 pt-5 pb-0 mb-16 ">
                        <h1 class="h2 text-white mb-0">Notificações</h1>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="pb-12 mt-lg-n18 mt-n10">
        <div class="container">
            <div class="row">
                <MenuProfile/>
                {/* <!-- dashboard subscriptions --> */}
                <div class="col-lg-9 col-md-8 col-12">
					<div class="card rounded-3 mb-4 ">
						{/* <!-- card header --> */}
						<div class="card-header bg-white p-4">
							<h3 class="mb-0 h4">Marketing & Propaganda</h3>
						</div>
						{/* <!-- card body --> */}
						<div class="card-body p-4">
							<ul class="list-group list-group-flush">
								<li class="list-group-item px-0 pt-0 pb-3">
									<div class="d-flex justify-content-between">
										<div>
											<h5 class="mb-1">Promoções</h5>
											<p class="mb-0">
												Não se preocupe! Enviaremos apenas notificações relevantes ao seu perfil.
											</p>
										</div>
										<div>
											<div class="form-check form-switch">
												<input type="checkbox" class="form-check-input" id="salesSwitch" checked />
												<label class="form-check-label" for="salesSwitch"></label>
											</div>
										</div>
									</div>
								</li>
								<li class="list-group-item px-0 py-3">
									<div class="d-flex justify-content-between">
										<div>
											<h5 class="mb-1">Atualizações de Produtos</h5>
											<p class="mb-0">
												Maiores chances de ter um produto atualizado.
											</p>
										</div>
										<div>
											<div class="form-check form-switch">
												<input type="checkbox" class="form-check-input" id="productSwitch" />
												<label class="form-check-label" for="productSwitch"></label>
											</div>
										</div>
									</div>
								</li>
								<li class="list-group-item px-0 py-3">
									<div class="d-flex justify-content-between">
										<div>
											<h5 class="mb-1">Notícias</h5>
											<p class="mb-0">
												Atualizações dos nossos posts e notícias sobre as áreas de negócios alinhadas ao seu interesse.
											</p>
										</div>
										<div>
											<div class="form-check form-switch">
												<input type="checkbox" class="form-check-input" id="newsletterSwitch" checked />
												<label class="form-check-label" for="newsletterSwitch"></label>
											</div>
										</div>
									</div>
								</li>
								<li class="list-group-item px-0 py-3">
									<div class="d-flex justify-content-between">
										<div>
											<h5 class="mb-1">Alertas de Segurança</h5>
											<p class="mb-0">
												Alertaremos sobre a segurança do seu acesso. Envianod notificações sobre seus acessos.
											</p>
										</div>
										<div>
											<div class="form-check form-switch">
												<input type="checkbox" class="form-check-input" id="securitySwitch" />
												<label class="form-check-label" for="securitySwitch"></label>
											</div>
										</div>
									</div>
								</li>
								<li class="list-group-item px-0 pt-3 pb-0">
									<div class="d-flex justify-content-between">
										<div>
											<h5 class="mb-1">Eventos e Ofertas dos nossos Cursos</h5>
											<p class="mb-0">
												Enviaremos notificações sobre eventos e palestras, bem como ofertas dos nossos cursos.
											</p>
										</div>
										<div>
											<div class="form-check form-switch">
												<input type="checkbox" class="form-check-input" id="eventSwitch" checked />
												<label class="form-check-label" for="eventSwitch"></label>
											</div>
										</div>
									</div>
								</li>
							</ul>
						</div>
					</div>
				</div>
            </div>
        </div>
    </div>
    </>

  )
}

export default Notificacoes;