import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

function Login() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate(); // Substituído useHistory por useNavigate

  const handleLogin = () => {
    // Aqui você pode implementar a lógica de autenticação
    // Por enquanto, vamos apenas verificar se o e-mail e a senha correspondem
    if (email === 'sullivan@teste.com' && password === '123456') {
      // Se o login for bem-sucedido, redirecione para a página desejada
      navigate('/class'); // Substituído history.push por navigate
    } else {
      // Caso contrário, exiba uma mensagem de erro
      setError('Credenciais inválidas');
    }
  };

  return (
    <div className="min-vh-100 d-flex align-items-center" style={{ background: '#d6d6d6' }}>
      <div className="container">
        <div className="row">
          <div className="offset-lg-3 col-lg-6 col-12 mb-4">
            <a href="/" className="d-flex justify-content-center">
              <img src="/img/Frame.svg" width="50%" alt="" />
            </a>
            <div className="bg-dark p-4 p-lg-8 rounded-3">
              <form>
                <h2 className="mb-2 text-white">Bem-Vindo!</h2>
                <p className="mb-4 text-muted">Preencha os campos abaixo com seus dados de acesso</p>
                <div className="mb-3">
                  <label htmlFor="email" className="form-label text-white-50">Email </label>
                  <input type="email" id="email" className="form-control border-0" placeholder="Insira seu E-mail" required="" value={email} onChange={(e) => setEmail(e.target.value)} />
                </div>
                <div className="mb-3 mb-4">
                  <label htmlFor="password" className="form-label  text-white-50">Senha</label>
                  <input type="password" id="password" className="form-control border-0" placeholder="Insira sua Senha" required="" value={password} onChange={(e) => setPassword(e.target.value)} />
                </div>
                <div className="d-grid">
                  <button className="btn btn-primary" type="button" onClick={handleLogin}>
                    Acessar
                  </button>
                </div>
                {error && <p className="text-danger">{error}</p>}
                <div className="d-lg-flex justify-content-between mt-4 mb-2">
                  <p className="text-white font-14">
                    Não possui cadastro ainda? <a href="/cadastro" className="text-white btn-destac"> Inscreva-se já!</a>
                  </p>
                </div>
                  <p className="font-14">
                    <a href="/password-reset" className="bg-white-soft">Esqueci Minha Senha</a>
                  </p>
              </form>
              <p id="response-message"></p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;
