import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { Router, Header, Footer, CeoSection, ServicosSection, AlunosDizem, Clientes, Contato, Login, Team, BusinessIntelligence, MenuProfile, CustomerProfile, Assinatura, Seguranca, Notificacoes, Usuarios, NumerosIndex, CourseTable, NavCoursesIndex, Class, AboutCorp, Cadastro, BackToTop, PasswordReset, Portifolio, Parallax, Planejamento, SectionMove, SectionTwoPort, BIServices, Process, NossosCursos, Filters, BancoDeDados } from './imports';
import { BrowserRouter } from 'react-router-dom';

const AppRoutes = () => (
  <BrowserRouter>
    <Routes>
      <Route path="/login" element={<Login />} />
      <Route path="/team" element={<TeamPage />} />
      <Route path="/business-intelligence" element={<BusinessIntelligence_ />} />
      <Route path="/banco-de-dados" element={<BancoDeDados_ />} />
      <Route path="/class" element={<Class />} />
      <Route path="/" element={<HomePage />} />
      <Route path="/customerProfile" element={<CustomerProfile_ />} />
      <Route path="/assinatura" element={<Assinatura />} />
      <Route path="/menuProfile" element={<MenuProfile />} />
      <Route path="/seguranca" element={<Seguranca />} />
      <Route path="/notificacoes" element={<Notificacoes />} />
      <Route path="/usuarios" element={<Usuarios />} />
      <Route path="/nossoscursos" element={<NossosCursos_ />} />
      <Route path="/aboutcorp" element={<AboutCorp_/>}/>
      <Route path='/cadastro' element={<Cadastro />}/>
      <Route path='/password-Reset' element={<PasswordReset />}/>
      <Route path='/portifolio' element={<Portifolio_ />}/>
      <Route path='/planejamento' element={<Planejamento_ />}/>
      <Route path='/bi-services' element={<BIServices_ />}/>
      <Route path='/process' element={<Process_ />}/>
    </Routes>
  </BrowserRouter>
);

export default AppRoutes;

function HomePage() {
  return (
    <>
      <Header />
      <MainContent />
      <Footer />
    </>
  );
}

function MainContent() {
  return (
    <>
      <CeoSection />
      <ServicosSection />
      <Parallax />
      <AlunosDizem />
      <SectionMove/>
      <Contato />
      <BackToTop/>
    </>
  );
}

function TeamPage() {
  return (
    <>
      <Header />
      <Team />
      <Footer />
    </>
  );
}

function BusinessIntelligence_() {
  return (
    <>
      <Header />
      <BusinessIntelligence />
      <Footer />
    </>
  );
}

function BancoDeDados_() {
  return (
    <>
      <Header />
      <BancoDeDados />
      <Footer />
    </>
  );
}

function CustomerProfile_(){
  return(
    <>
    <CustomerProfile />
    </>
  )
}

function NossosCursos_() {
  return (
    <>
      <Header />
      <NumerosIndex/>
      <Filters/>
      <CourseTable/>
      <NossosCursos/>
      <Footer />
    </>
  );
}

function AboutCorp_(){
  return (
    <>
    <Header />
    <AboutCorp/>
    <Footer />
    </>
  )
}

function Portifolio_(){
  return (
    <>
    <Header />
    <SectionTwoPort/>
    <Portifolio/>
    <Contato/>
    <BackToTop/>
    <Footer />
    </>
  )
}

function Planejamento_(){
  return(
    <>
    <Header/>
    <Planejamento/>
    <Contato />
    <BackToTop/>
    <Footer/>
    </>
  )
}

function BIServices_(){
  return(
    <>
    <Header/>
    <BIServices />
    <Contato/>
    <BackToTop/>
    <Footer/>
    </>
  )}

function Process_(){
  return (
    <>    
    <Header/>
    <Process />
    <Contato/>
    <BackToTop/>
    <Footer/>  
    </>
  )
}
