import React from 'react'

function SectionMove(){
  return(
    <>
    <div className='presentation-page'>
    <div className='section section-basic-components'>
    <div className='container'>
      <div className='row'>
      <div class="col-lg-5 col-md-7 ml-auto mr-auto">
      <h2 class="display-6">Elementos Personalizados</h2>
      <span class="font-16 text-muted">Transforme sua visão e suas ideias em realidade</span>
      <p >Nossos serviços de desenvolvimento oferecem uma abordagem personalizada para atender às suas necessidades exclusivas. Com uma ampla gama de opções de personalização, você pode ajustar as cores, os tamanhos e as animações para criar uma experiência verdadeiramente única para seus projetos. Deixe-nos ajudá-lo a dar vida à sua visão às suas ideias para destacar-se da multidão..</p>
      </div>
      <div class="col-lg-6 col-md-12">
      <div class="image-container-3">
      <img class="components-macbook" src="/img/ipad.webp" alt=""/>
      <img class="table-img" src="/img/table.jpg" alt=""/>
      <img class="share-btn-img" src="/img/share-btn.webp" alt=""/>
      <img class="coloured-card-btn-img" src="/img/coloured-card-with-btn.webp" alt=""/>
      <img class="coloured-card-img" src="/img/coloured-card.jpg" alt=""/>
      <img class="social-img" src="/img/social-row.webp" alt=""/>
      <img class="linkedin-btn-img" src="/img/linkedin-btn.webp" alt=""/>
      </div>
</div>
      </div>
    </div>
    </div>
    </div>
    </>
  )
}

export default SectionMove;